<template>
	<div class="mt-4">
		<SkeletonLoader  />
		<div class="mt-10 hidden md:block"><SkeletonLoader  /></div>
		<div class="mt-10 hidden md:block"><SkeletonLoader  /></div>
	</div>
</template>

<script>
	import SkeletonLoader from './CardSkeletonLoader';

	export default {
		name: 'BookingLoader',
		components: {
			SkeletonLoader
		}
	}
</script>