import Form from '../form';
export default class BookingManager extends Form {
	constructor (data) {
		super(data);
		this.skeletonLoader = false;
	}	

	async bookings(apiEnd, queies) {
		this.skeletonLoader = true;
        let response =  await this.get(`${apiEnd}?trip-status=${queies.tripStatus}&status=${queies.bookingStatus}`);
        this.skeletonLoader = false
        return response;
	}		

	showToaster(message){
        this.network.showSuccessMessage(message)
    }
}