import * as PusherPushNotifications from "@pusher/push-notifications-web"
export const clearDeviceInterests = () => {
    window.navigator.serviceWorker.ready.then(serviceWorkerRegistration => {
        const beamsClient = new PusherPushNotifications.Client({
            instanceId: '3ba48c17-168b-4bc1-808c-c6436c7dcf28',
            serviceWorkerRegistration: serviceWorkerRegistration,
        });
        beamsClient.start()
            .then(() => {
                // The user will now not be subscribed to any interests
                return beamsClient.clearDeviceInterests();
            })
            .then(() => console.log('Device interests have been cleared'))
            .catch(e => console.error('Could not clear device interests', e));
    });
}