<template>
	<div>
		<label for="pickup" class="text-xs text-gray-600 capitalize">
			{{ title }}
		</label>
		<div>
			<div v-if="eventName == 'initialState'">
				<button
					class="
						bg-green-100
						text-gray-500
						px-4
						py-1
						rounded
						btn-hover-opacity-effect
						w-full
					"
					@click.passive="setDistrictSelectionWithoutDivisionState"
				>
					<span v-if="$store.state.language == 'en'">Select Location</span>
					<span v-else>সিলেক্ট লোকেশন</span>
				</button>
			</div>

			<div class="flex flex-wrap justify-left items-center">
				<p v-for="(item, i) in selected" :key="i">
					<span class="text-gray-500 font-semibold">
						{{ $store.state.language == "en" ? item.name.en : item.name.bn }}
					</span>
					<span class="px-1 text-gray-400" v-if="i + 1 < selected.length"
						>></span
					>
				</p>
				<i
					@click.prevent="setPrimaryState"
					v-if="
						eventName !== 'initialState' &&
						eventName !== 'onSelectDivision' &&
						eventName !== 'onSelectDistrictWithoutDivision'
					"
					class="
						mdi mdi-close
						cursor-pointer
						text-2xl text-gray-400
						hover:text-red-400
						transition
						duration-300
					"
				>
				</i>
				<span class="text-gray-400">{{
					$store.state.language == "en" ? placeholder.en : placeholder.bn
				}}</span>
			</div>
		</div>
		<div
			class="h-40 bg-gray-100 mb-10"
			v-if="eventName !== 'doneSelection' && eventName !== 'initialState'"
		>
			<input
				v-if="$store.state.language == 'bn'"
				class="w-full p-1 border border-gray-200 rounded text-gray-400 text-sm"
				:placeholder="placeholder.bn + ' খুজুন...'"
				type="text"
				v-model="key"
				@input="filterData(key)"
			/>

			<input
				v-if="$store.state.language == 'en'"
				class="w-full p-1 border border-gray-200 rounded text-gray-400 text-sm"
				:placeholder="placeholder.en + ' Search...'"
				type="text"
				v-model="key"
				@input="filterData(key)"
			/>
			<div class="relative h-40 overflow-y-auto bg-gray-100">
				<div v-for="(item, i) in data" :key="i">
					<button
						@click.prevent="handleClick(item)"
						class="
							text-gray-500
							btn-hover-opacity-effect
							w-full
							text-left
							px-1
							hover:bg-gray-200
						"
					>
						{{ $store.state.language == "en" ? item.name.en : item.name.bn }}
					</button>
				</div>
			</div>
			<Loader :show="fetchProgress" />
		</div>
	</div>
</template>

<script>
import Loader from "@/components/atom/LoadingComponent";
import getGeoLocation from "@/services/module/api.geolocation";
import { computed } from "vue";

export default {
	name: "GeoSelectorWithoutDivisionUnion",
	components: {
		Loader,
	},
	data() {
		return {
			key: "",
		};
	},
	methods: {
		handleClick(item) {
			if (this.fetchProgress) return;
			this.key = "";
			if (this.eventName === "onSelectDivision") {
				this.setSelected(item);
				this.eventName = "onSelectDistrict";
				this.getDistrict(item.id);
			} else if (this.eventName === "onSelectDistrictWithoutDivision") {
				this.setSelected(item);
				this.eventName = "onSelectSubDistrict";
				this.geSubDistrict(item.id);
			} else if (this.eventName === "onSelectDistrict") {
				this.setSelected(item);
				this.eventName = "onSelectSubDistrict";
				this.geSubDistrict(item.id);
			} else if (this.eventName === "onSelectSubDistrict") {
				this.setSelected(item);
				this.eventName = "doneSelection";
				this.$emit("onSelectionDone", this.selectionType);
			} else {
				this.setSelected(item);
				this.eventName = "doneSelection";
				this.$emit("onSelectionDone", this.selectionType);
			}
		},

		getSelectedData() {
			return this.selected;
		},

		setSelectedArr(value) {
			this.eventName = "doneSelection";
			this.selected = value;
		},
		setPrimaryState() {
			this.setInitialState();
			this.$emit("onSetInitialState", this.selectionType);
		},
	},
	setup() {
		const {
			data,
			selected,
			setSelected,
			eventName,
			fetchProgress,
			getDistrict,
			geSubDistrict,
			getUnion,
			setInitialState,
			setDivisionSelectionState,
			setDistrictSelectionWithoutDivisionState,
			filterData,
		} = getGeoLocation();

		const placeholder = computed(() => {
			let obj = { bn: "", en: "" };
			if (eventName.value == "onSelectDivision")
				obj = { bn: "বিভাগ", en: "Division" };
			if (eventName.value == "onSelectDistrict")
				obj = { bn: "জেলা", en: "District" };
			if (eventName.value == "onSelectDistrictWithoutDivision")
				obj = { bn: "জেলা ", en: "District" };
			if (eventName.value == "onSelectSubDistrict")
				obj = { bn: "উপজেলা/সিটি", en: "SubDistrict/City" };
			if (eventName.value == "onSelectUnion")
				obj = { bn: "ইউনিয়ন/এলাকা", en: "Union/Area" };
			return obj;
		});
		return {
			data,
			selected,
			setSelected,
			fetchProgress,
			eventName,
			getDistrict,
			geSubDistrict,
			getUnion,
			setInitialState,
			setDivisionSelectionState,
			setDistrictSelectionWithoutDivisionState,
			filterData,
			placeholder,
		};
	},
	props: {
		title: {
			required: true,
			type: String,
		},
		isLangEn: {
			type: Boolean,
			default: false,
		},
		selectionType: {
			type: String,
		},
	},
};
</script>
