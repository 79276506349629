const notificationMixin = {
	methods: {
		displayWebNotification(title, body, url, icon='/img/icons/favicon-32x32.png' ) {
			var options = {
			  body: body,
			  icon: icon,
			}
			var notification = new Notification(title, options);

			notification.onclick = function(event) {
				event.preventDefault();
				window.open(url, '_blank');
			}
		},

		askNotificationPermission() {	
			return new Promise((resolve, reject) => {
				Notification.requestPermission().then(function (permission) {
				return permission;
				})
				.then(per => {
					resolve( per);
				})
				.catch(err => {
					throw new Error(`Something went wrong - ${err}`);
				})
			}).then((value) => {
				if (value === "granted") {
						return true;
					} else {
						return false;
					}
			})
			
		},

	}
	

}

export default notificationMixin	;