<template>
    <div class="p-4 rounded-md mt-4">
        <div class="text-center">
            <h2 class="text-gray-700 capitalize text-lg font-semibold w-full">
                আপনার প্রোফাইল আপডেট করুন
            </h2>
            <p class="text-sm w-full mt-2 text-primary md:text-center">
                ঠিকানা, এন আই ডি নাম্বার এবং জন্ম তারিখ আবশ্যিক। <br> দয়া করে প্রোফাইল সম্পূর্ণ করুন।
            </p>
        </div>
        <div class="my-2">
            <label for="nid" class="text-xs text-gray-600 capitalize">{{ $t("card.nid") }}</label>
            <input
                id="nid"
                name="nid"
                v-model="form.nid"
                class="text-gray-500 rounded bg-gray-100 w-full p-2 border border-gray-200 text-xs"
                type="text"
                :placeholder="$t('signUpPlaceholder.nid')"
            />
        </div>

        <div>
            <div class="mb-2 w-full">
                <label for="date_of_birth" class="text-xs text-gray-600 capitalize">{{ $t("card.date_of_birth") }}</label>
                <div class="flex">
                    <div class="w-full">
                        <label for="date" class="text-xs text-gray-600 capitalize">{{ $t("card.date") }}</label>
                        <select
                            v-model="form.date"
                            id="date"
                            class="text-gray-500 rounded bg-gray-100  w-full p-2 border border-gray-200 text-xs"
                        >
                            <option v-for="date in dates" :key="date" :value="date">
                                {{ $i18n.locale === "bn" ? Math.abs(date).toLocaleString("bn-BD").replace(",", "") : date }}
                            </option>
                        </select>
                    </div>
                    <div class="w-full">
                        <label for="month" class="text-xs text-gray-600 capitalize">{{ $t("card.month") }}</label>
                        <select
                            v-model="form.month"
                            id="month"
                            class="text-gray-500 rounded bg-gray-100  w-full p-2 border border-gray-200 text-xs">
                            <option v-for="month in months" :key="month" :value="month.value">
                                {{ $i18n.locale === "bn" ? month.bdName : month.enName }}
                            </option>
                        </select>
                    </div>
                    <div class="w-full">
                        <label for="year" class="text-xs text-gray-600 capitalize">{{ $t("card.year") }}</label>
                        <select
                            v-model="form.year"
                            id="year"
                            class="text-gray-500 rounded bg-gray-100  w-full p-2 border border-gray-200 text-xs">
                            <option v-for="year in years" :key="year" :value="year">
                                {{ $i18n.locale === "bn" ? Math.abs(year).toLocaleString("bn-BD").replace(",", "") : year }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="mb-2 w-ful">
                <p class="text-xs text-gray-600">স্থায়ী ঠিকানা</p>
            </div>
            <div class="mb-2 w-ful">
                <div class="flex flex-col items-start">
                    <div class="w-full mb-2">
                        <div class="bg-gray-100 w-full border border-4ray-200 py-1 px-2 flex justify-between">
                            <input
                                class="bg-gray-100 text-xs py-1 w-3/4"
                                placeholder="জেলা"
                                @click="showDistrictDropdownClick"
                                v-model="district_key"
                                @input="filterDataItem(district_key)"
                            />
                            <span
                                v-if="showDistrictDropdown"
                                class="mdi mdi-close-circle-outline text-red-500 w-1/4 flex justify-end"
                                @click="districtClear"
                            ></span>
                        </div>
                        <div v-if="showDistrictDropdown" class="w-full bg-white border border-4ray-200 max-h-48 overflow-y-scroll">
                            <ul v-for="item in filteredDistricts" class="list-none p-0 m-0">
                                <li
                                    class="py-2.5 px-2 cursor-pointer hover:bg-gray-200"
                                    @click="onSelectDistrict(item)"
                                    :key="item.id"
                                >
                                    {{item.name.bn}}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="w-full">
                        <div class="bg-gray-100 w-full border border-4ray-200 py-1 px-2 flex justify-between">
                            <input
                                class="bg-gray-100 text-xs py-1 w-3/4"
                                placeholder="থানা"
                                @click="showSubDistrictDropdownClick"
                                v-model="sub_district_key"
                                @input="filterSubDataItem(sub_district_key)"
                            />
                            <span
                                v-if="showSubDistrictDropdown"
                                class="mdi mdi-close-circle-outline text-red-500 w-1/4 flex justify-end"
                                @click="subDistrictClear"
                            ></span>
                        </div>
                        <div v-if="showSubDistrictDropdown" class="w-full bg-white border border-4ray-200 max-h-48 overflow-y-scroll">
                            <ul v-for="item in filteredSubDistricts" class="list-none p-0 m-0">
                                <li
                                    class="py-2.5 px-2 cursor-pointer hover:bg-gray-200"
                                    @click="onSelectSubDistrict(item)"
                                    :key="item.id"
                                >
                                    {{item.name.bn}}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <button
                class="bg-green-400 text-gray-100 rounded w-full mt-4 hover:bg-green-700 transition duration-400  py-3"
                type="submit"
                @click="onUpdateNid"
            >
                <div class="flex items-center justify-center">
                    <span class="mdi mdi-loading mdi-spin mr-2" v-if="isSubmitting"></span>
                    <span class="text-grey-100 uppercase text-xs">আপডেট প্রোফাইল</span>
                </div>
            </button>
        </div>
    </div>
</template>

<script>
import {updateProfileImage} from "@/services/module/profile";
import {mapMutations} from "vuex";
import {inject} from "vue";
import {fetchDistrictWithoutDivision, fetchSubDistrict} from "@/services/module/api.geolocation";

export default {
    name: "NidDobUpdate",
    props: {
        profileData: {
            required: true,
        },
    },
    data() {
        return {
            form: {
                nid: "",
                date_of_birth: "",
                date: "",
                month: "",
                year: "",
                district_id: "",
                district_name: "",
                sub_district_id: "",
                sub_district_name: "",
            },
            years : [

            ],
            profile: {
                _method: "",
                nid: "",
                date_of_birth: "",
                district_id: "",
                district_name: "",
                sub_district_id: "",
                sub_district_name: "",
                password: "",
            },
            months : [
                {
                    value: 1,
                    bdName: "জানুয়ারি",
                    enName: "January",
                },
                {
                    value: 2,
                    bdName: "ফেব্রুয়ারি",
                    enName: "February",
                },
                {
                    value: 3,
                    bdName: "মার্চ",
                    enName: "March",
                },
                {
                    value: 4,
                    bdName: "এপ্রিল",
                    enName: "April",
                },
                {
                    value: 5,
                    bdName: "মে",
                    enName: "May",
                },
                {
                    value: 6,
                    bdName: "জুন",
                    enName: "June",
                },
                {
                    value: 7,
                    bdName: "জুলাই",
                    enName: "July",
                },
                {
                    value: 8,
                    bdName: "আগস্ট",
                    enName: "August",
                },
                {
                    value: 9,
                    bdName: "সেপ্টেম্বর",
                    enName: "September",
                },
                {
                    value: 10,
                    bdName: "অক্টোবর",
                    enName: "October",
                },
                {
                    value: 11,
                    bdName: "নভেম্বর",
                    enName: "November",
                },
                {
                    value: 12,
                    bdName: "ডিসেম্বর",
                    enName: "December",
                },
            ],
            dates : [],
            district_key: '',
            sub_district_key: '',
            districtWithoutDivision: [],
            filteredDistricts: [],
            subDistricts: [],
            filteredSubDistricts: [],
            showDistrictDropdown: false,
            showSubDistrictDropdown: false,
            isSubmitting: false,
        };
    },
    setup() {
        const showError = inject("showError");
        const showSuccess = inject("showSuccess");
        return { showError, showSuccess };
    },
    methods: {
        ...mapMutations({
            setToaster: "setToaster",
        }),
        padZero(number) {
            return number.toString().padStart(2, '0');
        },
        async onUpdateNid() {
            this.isSubmitting = true;
            if (this.form.nid == '') {
                this.showError(this.$t('signUpValidation.nid'))
                return;
            }
            if (this.form.nid) {
                var nidPattern = /^\d{10,17}$/;
                if (!nidPattern.test(this.form.nid)) {
                    this.showError(this.$t('signUpValidation.nid'));
                    return;
                }
            }
            if (this.form.year == '' || this.form.month == '' || this.form.date == '') {
                this.showError(this.$t('signUpValidation.date_of_birth'))
                return;
            }

            this.form.date_of_birth = this.form.year + "-" + this.padZero(this.form.month) + "-" + this.padZero(this.form.date);

            if (this.form.district_id == '') {
                this.showError(this.$t('signUpValidation.district'))
                return;
            }
            if (this.form.sub_district_id == '') {
                this.showError(this.$t('signUpValidation.sub_district'))
                return;
            }

            this.profile._method = "put";
            this.profile.nid = this.form.nid;
            this.profile.date_of_birth = this.form.date_of_birth;
            this.profile.district_id = this.form.district_id;
            this.profile.district_name = this.form.district_name;
            this.profile.sub_district_id = this.form.sub_district_id;
            this.profile.sub_district_name = this.form.sub_district_name;
            this.profile.password = "";
            let res = await updateProfileImage(this.profile);
            if (res?.message) {
                this.setToaster(res.message);
            }
            this.isSubmitting = false;
            if (res.success) {
                location.reload();
            }
        },

        setYears() {
            let currentYear = new Date().getFullYear() - 18
            for (let i = 0; i < 52; i++) {
                this.years.push(currentYear - i);
            }
        },
        setDates() {
            for (let i = 1; i <= 31; i++) {
                this.dates.push(i);
            }
        },

        setProfile(){
            this.form.nid = this.profileData.profile?.nid;
            if (this.profileData.profile?.date_of_birth) {
                let date = new Date(this.profileData.profile?.date_of_birth);
                this.form.date = date.getDate();
                this.form.month = date.getMonth() + 1;
                this.form.year = date.getFullYear();
            }
            this.form.district_id = this.profileData.profile?.district_id;
            this.form.district_name = this.profileData.profile?.district_name;
            this.form.sub_district_id = this.profileData.profile?.sub_district_id;
            this.form.sub_district_name = this.profileData.profile?.sub_district_name;

            console.log("Profile Data in form: ", this.form)
        },

        // district and sub-district dropdown
        async getDistrictWithoutDivision() {
            const response = await fetchDistrictWithoutDivision();
            this.districtWithoutDivision = response.data;
            this.filteredDistricts = this.districtWithoutDivision;
        },
        filterDataItem(district_key) {
            if (district_key === '') {
                this.filteredDistricts = this.districtWithoutDivision;
            } else {
                this.filteredDistricts = this.filteredDistricts.filter(item => {
                    return item.name.bn.includes(district_key.toLowerCase()) || item.name.en.toLowerCase().includes(district_key.toLowerCase());
                });
            }
        },
        filterSubDataItem(sub_district_key) {
            if (sub_district_key === '') {
                this.filteredSubDistricts = this.subDistricts;
            } else {
                this.filteredSubDistricts = this.filteredSubDistricts.filter(item => {
                    return item.name.bn.includes(sub_district_key.toLowerCase()) || item.name.en.toLowerCase().includes(sub_district_key.toLowerCase());
                });
            }
        },
        async getSubDistrict() {
            const response = await fetchSubDistrict(this.form.district_id);
            this.subDistricts = response.data;
            this.filteredSubDistricts = this.subDistricts;
        },
        onSelectDistrict(item) {
            this.form.district_id = item.id;
            this.showDistrictDropdown = false;
            this.district_key = item.name.bn;
            this.form.district_name = item.name.en;
            this.sub_district_key = '';
            this.getSubDistrict();
        },
        onSelectSubDistrict(item) {
            this.form.sub_district_id = item.id;
            this.form.sub_district_name = item.name.en;
            this.showSubDistrictDropdown = false;
            this.sub_district_key = item.name.bn;
        },
        showDistrictDropdownClick() {
            this.showDistrictDropdown = !this.showDistrictDropdown;
            this.showSubDistrictDropdown = false;
        },
        showSubDistrictDropdownClick() {
            this.showSubDistrictDropdown = !this.showSubDistrictDropdown;
            this.showDistrictDropdown = false;
        },
        districtClear() {
            this.district_key = '';
            this.form.district_name = '';
            this.form.sub_district_name = '';
            this.showDistrictDropdown = false;
            this.filteredDistricts = this.districtWithoutDivision;
            this.sub_district_key = '';
            this.showSubDistrictDropdown = false;
            this.filteredSubDistricts = [];
        },
        subDistrictClear() {
            this.sub_district_key = '';
            this.showSubDistrictDropdown = false;
        },
        // end district and sub-district dropdown
    },
    mounted() {
        this.setYears();
        this.setDates();
        this.getDistrictWithoutDivision();
        this.setProfile()
        console.log("Profile Data: ", this.profile)
    },
};
</script>