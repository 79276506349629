<template>
	<div>
		<div v-if="inputFieldStyleHorizontal">
			<div
				class="
					lg:h-32
					grid
					place-items-center place-content-center
					grid-cols-1
					sm:grid-cols-2
					md:grid-cols-3
					lg:grid-cols-4
					xl:grid-cols-5
					gap-4 gap
					px-5
				"
			>
				<PickupInput
					class=""
					:lat="pickup_point_lat"
					:lng="pickup_point_lng"
					:full_address="pickup_full_address"
					:division="pickup_division"
					@onClicked="pickUpData"
				/>
				<DestinationInput
					class=""
					:lat="destination_lat"
					:lng="destination_lng"
					:full_address="destination_full_address"
					:division="destination_division"
					@onClicked="destinationData"
				/>
				<Datepicker class="" label="Departure Date" />

				<!-- passenger selection  -->
				<div
					class="
						p-2
						bg-white
						rounded-md
						border-l-8 border-primary
						shadow-sm
						w-64
						md:w-62
						sm:w-54
					"
				>
					<label for="select" class="text-xs text-gray-600 capitalize">{{
						$t("triCreateForm.carModel")
					}}</label>
					<div class="flex">
						<span class="mdi mdi-run text-primary pr-2"></span>
						<select @change="setModel" class="w-full">
							<option value="">-</option>
							<option
								v-for="model in carModels"
								:value="model.model"
								:key="model.id"
							>
								{{ model.model }}
							</option>
						</select>
					</div>
				</div>
				<!-- passenger selection  -->
				<button
					@click="$emit('onClickSearch')"
					class="
						px-5
						w-64
						sm:w-54
						md:w-64
						py-4
						text-center text-xl
						bg-primary
						text-white
						rounded
					"
				>
					Search
				</button>
			</div>
		</div>

		<div v-else>
			<div
				class="
					pickup-and-destination-search
					grid
					sm:grid-cols-4
					grid-cols-1
					gap-3
				"
			>
				<div
					class="
						pickup-search
						bg-white
						p-2
						rounded
						w-full
						flex
						gap-2
						items-center
						justify-center
						relative
					"
				>
					<div
						class="flex gap-2 cursor-pointer w-full h-full"
						@click="openSearchField('pickup')"
					>
						<span><img src="images/pickup.png" alt="" /></span>
						<span v-if="!selectedPickDivision">Pickup</span>
						<span v-else>
							{{ selectedPickDivision ? selectedPickDivision.name.en : "" }} >
							<span
								v-if="
									selectedPickDistrict && selectedPickDistrict.name.en == 'all'
								"
							>
								All of {{ selectedPickDivision.name.en }}
							</span>
							<span
								v-if="
									selectedPickDistrict && selectedPickDistrict.name.en != 'all'
								"
							>
								{{ selectedPickDistrict.name.en }}
							</span></span
						>
					</div>
					<div
						class="close-icon absolute text-right right-1 cursor-pointer"
						v-if="selectedPickDivision"
						@click="clearSelectedData('pickup')"
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							stroke="currentColor"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round"
							class="feather feather-x-circle"
						>
							<circle cx="12" cy="12" r="10"></circle>
							<line x1="15" y1="9" x2="9" y2="15"></line>
							<line x1="9" y1="9" x2="15" y2="15"></line>
						</svg>
					</div>
					<div
						v-if="openSearch == 'pickup' && opened"
						class="
							search-result-box
							pickup-locations
							absolute
							top-12
							w-full
							max-h-80
							min-h-fit
							bg-gray-200
							cursor-default
							overflow-y-auto
						"
					>
						<div
							class="division-district grid gap-1 py-2"
							v-if="showingStage == 'division-pick'"
						>
							<input
								class="p-2"
								type="search"
								placeholder="Search Division..."
								v-model="search"
								@keyup="filteredItems(divisions)"
							/>
							<div
								@click="getPickDistricts(division)"
								class="
									name
									p-2
									flex
									bg-gray-300
									hover:bg-gray-400
									cursor-pointer
								"
								v-for="(division, index) in filteredItems(divisions)"
								:key="index"
							>
								{{
									$store.state.language == "en"
										? division.name.en
										: division.name.bn
								}}
							</div>
						</div>

						<div
							class="division-district grid gap-1 py-2"
							v-if="showingStage == 'district-pick'"
						>
							<input
								class="p-2"
								type="search"
								placeholder="Search District..."
								v-model="search"
								@keyup="filteredItems(districts)"
							/>

							<div
								v-if="search == ''"
								@click="setPickDistrict('all')"
								class="
									name
									p-2
									flex
									bg-gray-300
									hover:bg-gray-400
									cursor-pointer
								"
							>
								<span> All of {{ selectedPickDivision.name.en }} </span>
							</div>
							<div
								@click="setPickDistrict(district)"
								class="
									name
									p-2
									flex
									bg-gray-300
									hover:bg-gray-400
									cursor-pointer
								"
								v-for="(district, index) in filteredItems(districts)"
								:key="index"
							>
								{{ selectedPickDivision ? selectedPickDivision.name.en : "" }} -
								{{
									$store.state.language == "en"
										? district.name.en
										: district.name.bn
								}}
							</div>
						</div>
					</div>
				</div>
				<div
					class="
						destination-search
						bg-white
						p-2
						w-full
						rounded
						justify-center
						cursor-pointer
						flex
						gap-2
						items-center
						relative
					"
				>
					<div
						class="flex gap-2 cursor-pointer w-full h-full"
						@click="openSearchField('destination')"
					>
						<span><img src="images/destination.png" alt="" /></span>
						<span v-if="!selectedDestDivision">Destination</span>
						<span v-else>
							{{ selectedDestDivision ? selectedDestDivision.name.en : "" }} >
							<span
								v-if="
									selectedDestDistrict && selectedDestDistrict.name.en == 'all'
								"
							>
								All of {{ selectedDestDivision.name.en }}
							</span>
							<span
								v-if="
									selectedDestDistrict && selectedDestDistrict.name.en != 'all'
								"
							>
								{{ selectedDestDistrict.name.en }}
							</span>
						</span>
					</div>
					<div
						class="close-icon absolute text-right right-1 cursor-pointer"
						v-if="selectedDestDivision"
						@click="clearSelectedData('destination')"
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							stroke="currentColor"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round"
							class="feather feather-x-circle"
						>
							<circle cx="12" cy="12" r="10"></circle>
							<line x1="15" y1="9" x2="9" y2="15"></line>
							<line x1="9" y1="9" x2="15" y2="15"></line>
						</svg>
					</div>
					<div
						v-if="openSearch == 'destination' && opened"
						class="
							search-result-box
							pickup-locations
							absolute
							top-12
							w-full
							max-h-80
							min-h-fit
							overflow-y-auto
							bg-gray-200
							cursor-default
						"
					>
						<div
							class="division-district grid gap-1 py-2"
							v-if="showingStage == 'division-dest'"
						>
							<input
								class="p-2"
								type="search"
								placeholder="Search Division..."
								v-model="search"
								@keyup="filteredItems(divisions)"
							/>
							<div
								@click="getDestDistricts(division)"
								class="
									name
									p-2
									flex
									bg-gray-300
									hover:bg-gray-400
									cursor-pointer
								"
								v-for="(division, index) in filteredItems(divisions)"
								:key="index"
							>
								{{
									$store.state.language == "en"
										? division.name.en
										: division.name.bn
								}}
							</div>
						</div>

						<div
							class="division-district grid gap-1 py-2"
							v-if="showingStage == 'district-dest'"
						>
							<input
								class="p-2"
								type="search"
								placeholder="Search District..."
								v-model="search"
								@keyup="filteredItems(districts)"
							/>
							<div
								v-if="search == ''"
								@click="setDestDistrict('all')"
								class="
									name
									p-2
									flex
									bg-gray-300
									hover:bg-gray-400
									cursor-pointer
								"
							>
								<span> All of {{ selectedDestDivision.name.en }} </span>
							</div>
							<div
								@click="setDestDistrict(district)"
								class="
									name
									p-2
									flex
									bg-gray-300
									hover:bg-gray-400
									cursor-pointer
								"
								v-for="(district, index) in filteredItems(districts)"
								:key="index"
							>
								{{ selectedDestDivision ? selectedDestDivision.name.en : "" }} -
								{{
									$store.state.language == "en"
										? district.name.en
										: district.name.bn
								}}
							</div>
						</div>
					</div>
				</div>

				<div class="search-icon bg-white p-2 rounded" @click="searchTrip()">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						stroke="currentColor"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
						class="feather feather-search"
					>
						<circle cx="11" cy="11" r="8"></circle>
						<line x1="21" y1="21" x2="16.65" y2="16.65"></line>
					</svg>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import axios from "axios";
import { mapMutations } from "vuex";
import { getCarModels } from "@/services/module/car.model";
import SearchableDropdown from "@/components/atom/SearchableDropdown";
import PickupInput from "@/components/atom/PickupInput";
import DestinationInput from "@/components/atom/DestinationInput";
import Datepicker from "@/components/atom/Datepicker";
export default {
	name: "TripDropdownSection",
	components: {
		SearchableDropdown,
		Datepicker,
		PickupInput,
		DestinationInput,
	},
	data() {
		return {
			pickup_point_lat: "",
			pickup_point_lng: "",
			pickup_full_address: "",
			pickup_division: "",
			destination_lat: "",
			destination_lng: "",
			destination_full_address: "",
			destination_division: "",
			carModels: [],
			showingStage: "division-pick",
			divisions: "",
			selectedPickDivision: null,
			selectedDestDivision: null,
			districts: "",
			selectedPickDistrict: null,
			selectedDestDistrict: null,
            selectedCarType: "",
			openSearch: "",
			search: "",
			opened: false,
		};
	},
	methods: {
		...mapMutations({
			setTripSearchQuery: "setTripSearchQuery",
			setDialog: "setDialog",
			setDialogComponent: "setDialogComponent",
		}),
		filteredItems(data) {
			return data.filter((item) => {
				return (
					item.name.en.toLowerCase().indexOf(this.search.toLowerCase()) > -1
				);
			});
		},
		getDivisions() {
			axios.get("/select/geo_loc/divisions").then((res) => {
				this.divisions = res.data.data;
			});
		},
		openSearchField(type) {
			this.opened = !this.opened;
			this.openSearch = type;
			if (type == "pickup") {
				this.showingStage = "division-pick";
			}
			if (type == "destination") {
				this.showingStage = "division-dest";
			}
		},
		getPickDistricts(division) {
			this.selectedPickDivision = division;
			axios.get(`/select/geo_loc/districts/${division.id}`).then((res) => {
				this.districts = res.data.data;
				if (this.openSearch == "pickup") {
					this.showingStage = "district-pick";
				}
			});
			this.search = "";
		},

		getDestDistricts(division) {
			this.selectedDestDivision = division;
			axios.get(`/select/geo_loc/districts/${division.id}`).then((res) => {
				this.districts = res.data.data;

				this.showingStage = "district-dest";
			});
			this.search = "";
		},

		setPickDistrict(data) {
			if (data == "all") {
				this.selectedPickDistrict = {
					id: null,
					name: {
						en: "all",
						bn: "all",
					},
				};
			} else {
				this.selectedPickDistrict = data;
			}
			this.openSearch = "";
			this.search = "";
			this.opened = false
		},

		setDestDistrict(data) {
			if (data == "all") {
				this.selectedDestDistrict = {
					id: null,
					name: {
						en: "all",
						bn: "all",
					},
				};
			} else {
				this.selectedDestDistrict = data;
			}
			this.openSearch = "";
			this.search = "";
			this.opened = false
		},

		searchTrip() {
			const trip_str = {
                car_type: this.selectedCarType,
				pdv: this.selectedPickDivision.id,
				pdst: this.selectedPickDistrict.id,
				ddv: this.selectedDestDivision.id,
				ddst: this.selectedDestDistrict.id,
			};

			//console.log(trip_str);

			this.$router.push({ name: "Feed", query: trip_str });
		},

		clearSelectedData(data) {
			if (data == "pickup") {
				this.selectedPickDivision = null;
				this.selectedPickDistrict = null;
			}
			if (data == "destination") {
				(this.selectedDestDivision = null), (this.selectedDestDistrict = null);
			}
		},
		async pickUpData(data) {
			this.pickup_point_lat = data.lat;
			this.pickup_point_lng = data.lng;
			this.pickup_full_address = data.full_address;
			this.pickup_division = data.division;
			this.setTripSearchQuery({ value: data.lat, key: "pickupLocation" });
		},
		async destinationData(data) {
			this.destination_lat = data.lat;
			this.destination_lng = data.lng;
			this.destination_full_address = data.full_address;
			this.destination_division = data.division;
			this.setTripSearchQuery({ value: data.lat, key: "destinationLocation" });
		},

		setModel(e) {
			this.setTripSearchQuery({ value: e.target.value, key: "model" });
		},

		async fetchCarModels() {
			let modelsResponse = await getCarModels();
			if (modelsResponse.success) {
				this.carModels = modelsResponse.data;
			}
		},
	},

	props: {
		inputFieldStyleHorizontal: {
			default: false,
		},
	},
	mounted() {
		this.getDivisions();
	},
	created() {
		this.fetchCarModels();
	},

	watch: {
		openSearch() {
			this.getDivisions();
		},

		selectedPickDivision() {
			this.selectedPickDistrict = null;
		},

		selectedDestDivision() {
			this.selectedDestDistrict = null;
		},
	},
};
</script>

<style>
.pickup-and-destination-search img {
	height: 22px;
	width: 22px;
	object-fit: contain;
}

.search-icon {
	width: 50px;
	display: flex;
	justify-content: center;
	max-height: 50px;
}

.search-result-box {
	z-index: 99 !important;
}

.search-icon:hover > svg {
	color: #e11d48;
	cursor: pointer;
}
</style>
