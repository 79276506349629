import Echo from 'laravel-echo';
import * as PusherPushNotifications from "@pusher/push-notifications-web"

window.Pusher = require('pusher-js');

let config = {
    authEndpoint :  process.env.VUE_APP_PUSHER_AUTH_END_POINT,
    broadcaster: 'pusher',
    key: process.env.VUE_APP_PUSHER_KEY,
    cluster: process.env.VUE_APP_PUSHER_CLUSTER,
    forceTLS: true,
    auth: {},
}

export const subscribeToPusher = (userID) => {
    window.navigator.serviceWorker.ready.then(serviceWorkerRegistration => {
        const beamsClient = new PusherPushNotifications.Client({
            instanceId: '3ba48c17-168b-4bc1-808c-c6436c7dcf28',
            serviceWorkerRegistration: serviceWorkerRegistration,
        });

        beamsClient.start()
            .then(() => beamsClient.addDeviceInterest('notification-'+userID))
            .then(() => console.log('Trip Create Notification Show!'))
            .catch(console.error);
    }
    );
}
// push to customer when provider bid
export const subscribeToPusherCustomer = (userID) => {
    window.navigator.serviceWorker.ready.then(serviceWorkerRegistration => {
        const beamsClient = new PusherPushNotifications.Client({
            instanceId: '3ba48c17-168b-4bc1-808c-c6436c7dcf28',
            serviceWorkerRegistration: serviceWorkerRegistration,
        });

        beamsClient.start()
            .then(() => beamsClient.addDeviceInterest('notification-customer-when-bid-'+userID))
            .then(() => console.log('Provider bid on customer trip!'))
            .catch(console.error);
    }
    );
}

export const configureEcho = (token) => {
    config.auth = {
        headers: {
            Authorization: 'Bearer ' + token
        },
    }
    try {
        let echo = new Echo(config);
        if(
            echo.options.authEndpoint === undefined
            || echo.options.key === undefined
            || echo.options.cluster === undefined
        ) {
            throw new Error ('Pusher credentials are specified incorrectly');
        }
        window.Echo = echo;
    }
    catch(error){
        throw new Error (error);
    }
}
