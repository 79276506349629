<template>
    <div>
        <div class="bg-bluishGray py-5" v-if="inputFieldStyleHorizontal">
            <TripDropdownSection class="max-w-7xl mx-auto" @onClickSearch="fetchTripLists"
                                 :inputFieldStyleHorizontal="inputFieldStyleHorizontal" />
        </div>
        <div
            v-if="shouldShowBanner"
            class="home-hero-section relative z-0  lg:py-32 md:py-20 py-10 bg-center bg-cover bg-no-repeat bg-opacity-25"
        >
            <!--this is now hidden-->
           <div class="hidden hero-section-btn-group absolute">
                <div class="grid">
                    <div class="px-3">
                        <div class="" v-if="user">
                            <router-link to="/feed">
                            <button
                                :class="[btn , btnPrimary]"
                                class="lg:py-3 py-1 lg:px-10 px-6 lg:text-lg text-sm"
                            >
                                
                                {{ $t("triCreateForm.tripSearch") }}
                            </button>
                            </router-link>
                        </div>

                        <div v-else>
                            <router-link to="/signup">
                            <button
                                :class="[btn , btnPrimary]"
                                class="lg:py-3 py-1 lg:px-10 px-6 lg:text-lg text-sm "
                            >
                                
                                {{ $t("card.signUp") }}
                            </button>
                            </router-link>
                        </div>

                    </div>
                
                </div>
            </div>
            <div class="flex flex-col items-center absolute centered">
                <div v-if="userType == 'provider'">
                    <div class="text-xl md:text-3xl text-center font-semibold">
                        আয় হবে বেশি বেশি
                    </div>
                    <div class="text-sm md:text-xl text-center font-semibold">
                        জমবে এবার ঈদের খুশি
                    </div>
                    <div class="text-center mt-5">
                        <router-link to="/feed">
                            <button
                                class="bg-primaryDeep text-white py-1 px-3 lg:text-lg text-sm"
                            >
                                {{ $t("card.availableTrip") }}
                            </button>
                        </router-link>
                    </div>
                </div>
                <div v-if="userType == 'customer'">
                    <div class="text-base md:text-3xl text-center font-semibold">
                        নিরাপদ এবং সাশ্রয়ী ভ্রমণ
                    </div>
                    <div class="text-sm md:text-xl text-center font-semibold">
                        Return Trip -ই সঙ্গী তখন
                    </div>
                    <div class="text-center mt-5">
                        <router-link to="/trip-request">
                            <button
                                class="bg-primaryDeep text-white py-1 px-3 lg:text-lg text-sm"
                            >
                                {{ $t("menu.createTrip") }}
                            </button>
                        </router-link>
                    </div>
                </div>
                <div v-if="!user">
                    <div class="text-base md:text-3xl text-center font-semibold">
                        নিরাপদ এবং সাশ্রয়ী ভ্রমণ
                    </div>
                    <div class="text-sm md:text-xl text-center font-semibold">
                        Return Trip -ই সঙ্গী তখন
                    </div>
                    <div class="text-center mt-5">
                        <router-link to="/signup">
                            <button
                                class="bg-primaryDeep text-white py-1 px-3 lg:text-lg text-sm"
                            >
                                {{ $t("card.signUp") }}
                            </button>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import TripDropdownSection from "@/components/molecule/home/TripDropdownSection";
import userMixin from "@/mixins/userMixin";
import TripManager from "@/services/module/trip";

import { mapGetters, mapMutations } from "vuex";

export default {
    name: "TripSearch",
    mixins: [userMixin],
    components: {
        TripDropdownSection
    },

    data() {
        return {
            tripManager: new TripManager({
                query: {
                    descOrderColumnName: "created_at",
                    createdBy: ""
                }
            }),
            btnWhite: "text-gray-700 bg-white",
            btnPrimary: "text-gray-100 bg-primary",
            btn:
                "capitalize rounded px-5 py-1 mr-2 px-1 text-xs focus:outline-none  hover:shadow-md opacity-90 hover:opacity-100"
        };
    },
    methods: {
        ...mapMutations({
            setDialogComponent: "setDialogComponent",
            setTrips: "trip/setTrips"
        }),
        showTripCreateForm() {
            if (this.user) {
                this.setDialogComponent("TripCreateForm");
            } else {
                this.$router.push({ path: "signin", query: { form: "create-trip" } });
            }
        },
        async fetchTripLists() {
            let data = {
                createdBy: this.createdBy,
                descOrderColumnName: "created_at"
            };

            let searchQuery = { ...data, ...this.getTripSearchQuery };

            window.scrollTo({
                top: 500,
                left: 0,
                behavior: "auto"
            });
            let res = await this.tripManager.searchTrip(searchQuery);
            if (res.success) {
                this.setTrips(res.data.data);
            }
        }
    },
    computed: {
        ...mapGetters({
            user: "user/getUser",
            getTripSearchQuery: "getTripSearchQuery"
        }),

        createdBy() {
            return this.user == null ? "PROVIDER" : this.userType == "customer" ? "PROVIDER" : "CUSTOMER";
        },

        shouldShowBanner() {
            return this.$route.name !== "Feed";
        }
    },
    mounted() {
        let form = this.$route.query.form;
        if (form == "create-trip") {
            this.setDialogComponent("TripCreateForm");
        }
    },

    props: {
        inputFieldStyleHorizontal: {
            default: false
        }
    }
};
</script>

<style>
  .home-hero-section {
      background-image: url("~/public/images/web-banner-2.jpg");
      background-repeat: no-repeat;
      height: 853px;
      min-height: 700px;
      background-size: cover;
  }
  .hero-section-btn-group {
    left: 260px;
    bottom: 100px;
  }

  .centered {
      top: 25%;
      left: 50%;
      transform: translate(-50%, -50%);
  }

  @media(max-width: 1600px) {
    .home-hero-section {
      min-height: 450px;
    }
    .hero-section-btn-group {
        left: 230px;
    }
  }
  @media(max-width: 1400px) {
    .home-hero-section {
      min-height: 450px;
    }
    .hero-section-btn-group {
        left: 230px;
    }
  }

  @media(max-width: 1200px) {
    .home-hero-section {
      min-height: 380px;
    }
    .hero-section-btn-group {
        left: 150px;
    }
  }

  @media(max-width: 992px) {
    .home-hero-section {
      min-height: 300px;
    }
    .hero-section-btn-group {
        left: 120px;
        bottom:80px;
    }
  }

  @media(max-width: 768px) {
    .home-hero-section {
      min-height: 280px;
    }
    .hero-section-btn-group {
        left: 120px;
    }
  }

  @media(max-width: 567px) {
    .home-hero-section {
      background-image: url("~/public/images/web-banner-2.jpg");
      height: 480px;
      min-height: 260px;
      background-size: cover;
    }
    .hero-section-btn-group {
        left: 35px;
    }
  }

  @media(max-width: 367px) {

    .hero-section-btn-group {
        left: 10px;
    }
  }


</style>
