export const links = [

	{
		title: "profile",
		link: "DriverProfile",
		type: "driver",
	},

	{
		title: "প্রোফাইল",
		link: "ProviderProfile",
		type: "provider",
	},

	{
		title: "বুকিং",
		link: "BookingConfirmed",
		type: "provider",
	},

	{
		title: "বিড সমূহ",
		link: "ProviderBookingFeed",
		type: "provider",
	},

	{
		title: "পেমেন্ট",
		link: "Wallet",
		type: "provider",
	},

	{
		title: "কার যুক্ত করুন",
		link: "CarIndex",
		type: "provider",
	},

];

export const linksWithIcon = [

	{
		title: "profile",
		link: "DriverProfile",
		type: "driver",
		icon: "mdi mdi-account",
	},

	{
		title: "বিড সমূহ",
		link: "ProviderBookingFeed",
		type: "provider",
		icon: "mdi mdi-car",
	},

	{
		title: "বুকিং",
		link: "BookingConfirmed",
		type: "provider",
		icon: "mdi mdi-car-multiple",
	},

	/*{
		title: "কার পার্কিং",
		link: "ParkingHome",
		type: "provider",
		icon: "mdi mdi-map-marker-radius",
	},*/
];
