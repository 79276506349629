const fotmatTimeMixin = {
	methods: {
		fotmatTime(time) {
	      let date = new Date(time);
	      return date.toLocaleDateString() + " " +  date.toLocaleTimeString()
	    },

	    concateTimeDate(date, time) {
	    	return date + " " + time;
	    },

	    getDateYMD(time) {
	    	let date = []
	        let year =  date.push(time.getFullYear());
	        let month = time.getMonth()+1;
	        if(month >= 10) date.push(month);
	        if(month < 10) date.push('0'+month);
	        let currentDate = time.getDate();
	        if(currentDate >= 10) date.push(currentDate);
	        if(currentDate < 10) date.push('0'+currentDate);
	        return date.join('-');
	    },

	    getTimeHour24(time) {
	    	let localTime = time.toLocaleString('en-GB');
	    	return localTime.split(',')[1].substr(1, 5);
	    }
	}
}

export default fotmatTimeMixin;