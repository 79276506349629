const state = () => ({
    feeds: [],
})

const getters = {
	getFeeds: (state) => state.feeds,
}

const mutations = {
	setFeeds: (state, payload) => state.feeds = [...state.feeds, ...payload],
	emptyExistingFeeds: (state) => state.feeds = [],
}

export default {
	namespaced: true,
	state,
	getters,
	mutations
}