<template>
	<div :class="divStyle">
		<p class="font-md mr-3 font-semibold text-gray-700 capitalize ">{{ text }}</p>
		<a
			target="_blank"
			v-for="(link, index) in socialLinks"
			:key="index"
			:href="link.link"
			:class="iconStyle"
		>	
			<span class="mdi" :class="[link.key, iconStyle]" ></span>
		</a>
	</div>
</template>
<script>
	import { mapGetters } from 'vuex';
	export default {
		name: 'SocialBox',
		props: {
			text: String,
			flexPosition: String,
			divStyle: {
				default: 'flex mt-5 w-full sm:w-min justify-center sm:justify-end sm:mt-0 items-center'
			},
			iconStyle: String,
			iconStyle: String
		},

		computed: {
			...mapGetters({
				siteData: "getSiteData"
			}),

			socialLinks() {
				let links = [];
				if(this.siteData.links){
					Object.keys(this.siteData.links).forEach(key => {
						links.push({link: this.siteData.links[key], key: 'mdi-' + key})
					})
				}
				return links;
			}
		}
	}
</script>