<template xmlns="http://www.w3.org/1999/html">
    <div class="provider-car-box mt-2">
        <!--<div v-if="car?.car_status !== 'active'"
             class="verification-message-box bg-gradient-to-r from-blue-200 to-cyan-200 p-3 md:text-base text-xs"><p>
            {{ $t("card.carMessage") }}</p>
        </div>-->
        <div class="item-border p-2 grid grid-cols-12 relative">
            <div class="text-bluishBlack flex gap-x-1 text-sm tracking-widest font-medium absolute right-1 top-1">
                <CarStatus :status="car?.car_status"/>
                <div
                    v-if="car?.car_status === 'verification_pending'"
                    class="w-8 h-8 bg-white rounded-md flex justify-center items-center text-bluishBlack text-xs cursor-pointer"
                    @click="goToEditCarForm(car?.car_id)"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                        <rect x="0.5" y="0.5" width="20" height="20" rx="3.5" fill="white"/>
                        <rect x="0.5" y="0.5" width="20" height="20" rx="3.5" stroke="#C7CDD2"/>
                        <path d="M14.1323 6.3125L15.122 5.32222C15.3283 5.11591 15.6081 5 15.8999 5C16.1916 5 16.4715 5.11591 16.6778 5.32222C16.8841 5.52854 17 5.80836 17 6.10013C17 6.3919 16.8841 6.67173 16.6778 6.87804L10.448 13.1078C10.1379 13.4177 9.75542 13.6456 9.33516 13.7707L7.75998 14.24L8.2293 12.6648C8.35443 12.2446 8.58226 11.8621 8.89223 11.552L14.1323 6.3125ZM14.1323 6.3125L15.6799 7.86011M14.7999 11.8934V14.68C14.7999 15.0301 14.6608 15.3658 14.4133 15.6134C14.1657 15.8609 13.83 16 13.4799 16H7.31998C6.9699 16 6.63416 15.8609 6.38661 15.6134C6.13907 15.3658 6 15.0301 6 14.68V8.5201C6 8.17002 6.13907 7.83427 6.38661 7.58673C6.63416 7.33919 6.9699 7.20012 7.31998 7.20012H10.1066" stroke="#9D9D9D" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>
            <div class="w-full col-span-3">
                <div>
                    <img
                        class="max-h-48 md:h-auto h-24 mx-auto rounded border border-gray-200"
                        :src="car?.images[0]?.file_path"
                        alt="Car Image"
                    >
                </div>
<!--                <Carousel :slides="carImages" :responsive="responsive">
                    <template v-slot="{ slide }">
                        <img
                            :src="slide"
                            class="max-h-48 md:h-auto h-24 mx-auto rounded"
                            alt=""
                        />
                    </template>
                </Carousel>-->
            </div>
            <div class="col-span-9 max-w-2xl">
                <div class="grid grid-cols-12 md:pl-8 md:pt-8">
                    <div class="col-span-12">
                        <div class="car-info-section grid gap-y-2 text-bluishBlack text-sm tracking-widest font-medium my-2">
                            <span class="heading-one md:text-lg text-sm">
                                {{ car?.brand?.name.toUpperCase() }}
                            </span>
                            <span class="heading-two md:text-base text-xs">
                                {{ car?.model }} {{ car?.manufacture_year }} ({{ car?.number_of_seat }} Seats)
                            </span>
                            <span v-if="car?.car_status !== 'active'" class="heading-three md:text-base">
                                {{ $t("card.carMessage") }}
                            </span>
                            <span v-else class="heading-two md:text-base text-xs">
                                {{ car.registration_number }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapMutations} from "vuex";
import Carousel from "@/components/atom/Carousel";
import {responsive} from "@/config/carousel";
import CarStatus from "@/components/atom/Status";

export default {
    name: "CarInformationCard",
    components: {
        Carousel,
        CarStatus,
    },

    data: () => ({
        responsive,
    }),

    props: {
        car: {
            type: Object,
        },
    },

    computed: {
        carImages() {
            let urls = this.car?.images?.map((i) => i.file_path)
            return urls.slice().sort(this.customSort)
        },
    },

    methods: {
        ...mapMutations({
            setDialogComponent: "setDialogComponent",
            setCarId: "setCarId",
            setCar: "setCar",
        }),

        handleCarModal() {
            this.setCar(this.car);
            this.setDialogComponent("CarEditForm");
        },

        goToEditCarForm(carId) {
            this.$router.push({name: "CarEdit", params: {id: carId}});
        },

        customSort(a, b) {
            const patternOrder = {
                "0-front": 0,
                "1-back": 1,
                "2-seat": 2
            };

            const matchA = a.match(/(\d+-\w+)\.(\w+)$/);
            const matchB = b.match(/(\d+-\w+)\.(\w+)$/);

            const keyA = matchA ? matchA[1] : "";
            const keyB = matchB ? matchB[1] : "";

            return patternOrder[keyA] - patternOrder[keyB];
        }
    },
};
</script>

<style>
.item-border {
    border-radius: 4px;
    border: 1px solid #C7CDD2;
    background: #FFF;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.06);
    padding: 8px;
}
.heading-one {
    color: #1A1A1A;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
}

.heading-two {
    color: #9D9D9D;
    font-family: "Noto Sans Bengali", sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
}

.heading-three {
    color: #D71920;
    font-family: "Noto Sans Bengali", sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
}
</style>
