<template>
	<div class="bg-white text-gray-700 py-5 mt-24">
		<div class="container mx-auto px-1 sm:px-3 max-w-7xl">
			<div
				class="
					flex
					justify-center
					sm:justify-between
					items-center
					pb-8
					border-b border-gray-500
					sm:flex-nowrap
					flex-wrap
				"
			>
				<div class="sm:flex w-full grid sm:px-0 px-2 gap-2 items-center">
					
					<div class="relative hidden">
						<p class="text-sm text-gray-100 mb-3">
							Join Our Newsletter
						</p>
						<input
							type="text"
							name=""
							class="
								w-full
								sm:w-64
								lg:w-96
								px-5
								py-2
								rounded
								bg-white
								text-xs text-black
							"
						/>
			
					</div>
				</div>
				<SocialBox
					iconStyle="text-2xl text-gray-700 cursor-pointer hover:text-primary transition duration-300 mr-1"
				/>
			</div>
		</div>

		<div class="container mx-auto border-b mt-10 border-gray-500 pb-8">
			<router-link class="footer-logo-image" to="/">
<!--				<img src="/images/rtb-logo.png" class="" alt="returntripbd" />-->
				<img src="/images/aside-square-black.png" class="" alt="returntripbd" />
			</router-link>

			<div
				class="px-3 flex justify-center items-center flex-wrap uppercase mt-10"
			>
				<router-link :to="item.route" v-for="(item, i) in links" :key="i">
					<span class="text-xs text-gray-500">{{ item.name }}</span>
					<span class="mx-2 text-gray-500" v-if="i + 1 < links.length">|</span>
				</router-link>
			</div>
		</div>
	</div>

	<div class="bg-gray-800 py-8 mb-20 sm:mb-0 md:block hidden">
		<p class="capitalize text-center text-xs font-semibold text-gray-300">
			Copyrights © <span id="year"></span> All Right
			<span class="text-primaryLight"> ReturnTrip</span>
		</p>
	</div>

	<BottomNav />
</template>

<script>
import { links } from "@/data/footerlinks";
import SocialBox from "@/components/atom/SocialBox";
import BottomNav from "@/components/layout/BottomNav";
export default {
	nam: "Footer",
	components: {
		SocialBox,
		BottomNav,
	},
	data() {
		return {
			links,
		};
	},

	mounted() {
		document.getElementById("year").innerHTML = new Date().getFullYear();
	},
	props: {
		footerLogo: {
			default: "/images/logo.png",
		},
	},
};
</script>

<style>
.footer-logo-image {
	width: 100%;
	max-width: 300px;
	margin: auto;
	display: flex;
	justify-content: center;
}
</style>
