const userMixin = {
	computed: {
		user() {
			return JSON.parse(localStorage.getItem("trip_auth_user"));
		},

		userId() {
			return this.user?.id;
		},

		userType() {
			return this.user?.type;
		},
	},

	mounted() {},

	watch: {
		$route: function (from, to) {},
	},
};

export default userMixin;
