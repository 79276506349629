<template>
  <div class="relative inline-block text-left ">
    <button
      class="w-64 md:w-62 sm:w-54 px-2 py-3 rounded-md border-l-8 border-primary shadow-sm  bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none "
    >
      <div class="flex justify-start items-center w-full">
        <div class="w-full text-left">
          <p class="text-xs text-gray-500">Departure Date</p>
          <div class="flex  w-full" for="departure_date" @click="openDate()">
            <span class="mdi mdi-calendar-range text-primary pr-2"></span>
            <input
              type="date"
              name="departure_date"
              ref="departure_date"
              class="  w-full"
              @change="onDateChange"
            />
          </div>
        </div>
      </div>
    </button>
  </div>
</template>
<style scoped>
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: block;
  -webkit-appearance: block;
}
</style>
<script>
  import {mapMutations} from 'vuex'
export default {
  methods: {
     ...mapMutations({ 
      setTripSearchQuery: 'setTripSearchQuery'
    }),

    openDate() {
      this.$refs.departure_date.select();
    },

    onDateChange(e) {
      this.setTripSearchQuery({value: e.target.value, key: 'startDate'})
    }
  },
};
</script>
