import { createApp } from "vue";

import MasterLayout from "./components/layout/MasterLayout";
import AuthLayout from "./components/layout/AuthLayout";
import ImpersonateLayout from "./components/layout/WithoutHeaderFooter";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
require("./services/pusher");

import App from "./App.vue";
import router from "./router";
import store from "./store";
//vue-flatpicker
import VueFlatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

//DropZone
import DropZone from "dropzone-vue";
// optionally import default styles
import "dropzone-vue/dist/dropzone-vue.common.css";
// Translations
import en from "./translations/en.json";
import bn from "./translations/bn.json";
import moment from "moment";
import { createI18n } from "vue-i18n";
import "./registerServiceWorker";
import "vue-social-chat/dist/style.css";

const i18n = createI18n({
	messages: {
		en,
		bn,
	},
	locale: "bn",
	fallbackLocale: "en",
});



const app = createApp(App);
// Global components
app.component("master-layout", MasterLayout);
app.component("auth-layout", AuthLayout);
app.component("impersonate-layout", ImpersonateLayout);
app
	.use(store)
	.use(i18n)
	.use(moment)
	.use(router)
	.use(VueFlatPickr)
	.use(DropZone)
	.use(Toast)
	.mount("#app");
