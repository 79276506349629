<template>
	<div v-if="show" class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-20">
		<img class="w-20" src="/images/loader.gif">
	</div>
</template>

<script>
	export default {
		name: 'loading',
		props: {
			show: {
				default: false,
			}
		}
	}
</script>