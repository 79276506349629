<template>
    <div class="">
        <div v-if="!secondPage" class="flex mb-4">
            <div class="flex-grow">
                <h2 class="text-gray-700 capitalize text-lg text-center font-semibold w-full">
                    {{ $t("card.heading") }}
                </h2>
            </div>

            <i
                @click="setDialog(false)"
                v-if="type !== 'individual-page'"
                class="mdi mdi-close cursor-pointer hover:text-primary text-gray-400 w-6 h-6 p-1 rounded-full bg-red-50 leading-none"
            ></i>
        </div>
        <form
            @submit.prevent="submit"
            @keydown="form.error.clear($event.target.name)"
        >
            <div v-show="!secondPage">
                <div class="relative w-full input-wrapper">
                    <label for="name" class="text-sm text-gray-600 capitalize">
                        {{ $t("triCreateForm.pickupLocation") }}
                    </label>
                    <vue-google-autocomplete
                        ref="address"
                        id="pickup"
                        types="establishment"
                        classname="w-full mb-2 p-2 border-2 border-gray-400 rounded"
                        :placeholder="$t('triCreateForm.selectPickupLocation')"
                        v-on:placechanged="getAddressData"
                        country="BD"
                        @focus="showPickupRecentSearches = true"
                        @blur="showPickupRecentSearches = false"
                    >
                    </vue-google-autocomplete>
                    <div v-if="isPickupSelected" class="remove mdi mdi-close-circle-outline bg-white p-1" @click="clearInput('pickup')"></div>
                    <span
                        class="text-red-400 block text-xs mt-1"
                        v-text="form.error.get('pickup')"
                        v-if="form.error.has('pickup')"
                    ></span>
                </div>
                <div class="relative mt-2 w-full input-wrapper">
                    <label for="name" class="text-sm text-gray-600 capitalize">
                        {{ $t("triCreateForm.destinationLocation") }}
                    </label>
                    <vue-google-autocomplete
                        ref="address1"
                        id="destination"
                        types="establishment"
                        classname="w-full mb-2 p-2 border-2 border-gray-400 rounded"
                        :placeholder="$t('triCreateForm.selectDestinationLocation')"
                        v-on:placechanged="getAddressData"
                        country="BD"
                        @focus="showDestinationRecentSearches = true"
                        @blur="showDestinationRecentSearches = false"
                    >
                    </vue-google-autocomplete>
                    <div v-if="isDestinationSelected" class="remove mdi mdi-close-circle-outline bg-white p-1" @click="clearInput('destination')"></div>
                    <span
                        class="text-red-400 block text-xs mt-1"
                        v-text="form.error.get('destination')"
                        v-if="form.error.has('destination')"
                    ></span>
                </div>

                <!--Journey Date and Time-->
                <div class="flex space-x-2">
                    <div class="relative w-full">
                        <label for="journey_date" class="text-sm text-gray-600 capitalize">
                            {{ $t("triCreateForm.journeyDate") }}
                        </label>
                        <CustomDate
                            class="tracking-widest text-gray-500 rounded bg-gray-100 mb-4 w-full p-2 border border-gray-200 text-xs"
                            @change-date="journeyDateChanged"
                            style="word-spacing: 10px"
                        />
                        <span
                            class="text-red-400 block text-xs mt-1"
                            v-text="form.error.get('journey_date')"
                            v-if="form.error.has('journey_date')"
                        ></span>
                    </div>
                    <div class="relative w-full">
                        <label for="journey_time" class="text-sm text-gray-600 capitalize">
                            {{ $t("triCreateForm.journeyTime") }}
                        </label>
                        <select
                            v-model="form.journey_time"
                            class="tracking-widest text-gray-500 rounded bg-gray-100 mb-4 w-full p-2 border border-gray-200 text-xs"
                            style="word-spacing: 10px"
                        >
                            <option
                                v-for="time in timeListItems"
                                :value="time.value" :key="time.value">
                                {{ time.time }}
                            </option>
                        </select>

                        <span
                            class="text-red-400 block text-xs mt-1"
                            v-text="form.error.get('journey_date')"
                            v-if="form.error.has('journey_date')"
                        ></span>
                    </div>
                </div>
                <!--End Journey Date and Time-->

                <!--Return Date and Time-->
                <div class="flex space-x-2" v-if="form.type == 'round'">
                    <div class="relative w-full">
                        <label for="return_date" class="text-sm text-gray-600 capitalize">
                            {{ $t("triCreateForm.returnDate") }}
                        </label>
                        <CustomDate
                            class="tracking-widest text-gray-500 rounded bg-gray-100 mb-4 w-full p-2 border border-gray-200 text-xs"
                            @change-date="returnDateChanged"
                            style="word-spacing: 10px"
                        />
                        <span
                            class="text-red-400 block text-xs mt-1"
                            v-text="form.error.get('return_date')"
                            v-if="form.error.has('return_date')"
                        ></span>
                    </div>
                    <div class="relative w-full">
                        <label for="return_time" class="text-sm text-gray-600 capitalize">
                            {{ $t("triCreateForm.returnTime") }}
                        </label>
                        <select
                            v-model="form.return_time"
                            class="tracking-widest text-gray-500 rounded bg-gray-100 mb-4 w-full p-2 border border-gray-200 text-xs"
                            style="word-spacing: 10px"
                        >
                            <option v-for="time in timeListItems" :value="time.value" :key="time.value">
                                {{ time.time }} he
                            </option>
                        </select>
                        <span
                            class="text-red-400 block text-xs mt-1"
                            v-text="form.error.get('return_date')"
                            v-if="form.error.has('return_date')"
                        ></span>
                    </div>
                </div>
                <!--End Journey Date and Time-->

                <div class="flex justify-between flex-wrap">
                    <div class="relative w-full" v-if="userType == 'provider'">
                        <label for="car" class="text-sm text-gray-600 capitalize">
                            {{ $t("triCreateForm.selectCar") }}
                        </label>
                        <select
                            class="text-gray-500 rounded bg-gray-100 mb-4 w-full p-2 h-9 border border-gray-200 text-xs"
                            id="car"
                            selected
                            @change="onCarChange"
                            v-model="form.car_id"
                        >
                            <option
                                :value="car.car_id"
                                :data_seat="car.number_of_seat"
                                v-for="car in provider_cars"
                                :key="car.driver_id"
                            >
                                {{ car.car_brand }}
                                {{ car.model }}
                                <span class="text-gray-300">{{ car.manufacture_year }}</span>
                                ({{ car.number_of_seat }} seat)
                            </option>
                        </select>

                        <!--Car Empty Section-->
                        <div class="empty-cars text-base mt-1 mb-1" v-if="(provider_cars.length < 1) && !carLoading">
                            <span class="text-red-500">No cars found! </span>
                            <router-link class="ml-1 px-2 py-1 cursor-pointer bg-green-500 text-white rounded-sm" to="/provider/car/store" @click="setDialog(false)">Add Car</router-link>
                        </div>
                        <!--End Car Empty Section-->

                    </div>

                    <div class="seat-number ride-type asking-price flex md:gap-2 gap-1 w-full">
                        <div class="w-full no-of-passengersorseat">
                            <label

                                class="text-xs text-gray-600 capitalize"

                            >
                                {{ $t("triCreateForm.seat") }}
                            </label>

                            <input
                                v-model="form.no_of_seat"
                                class="text-gray-500 rounded bg-gray-100 mb-4 w-full p-2 h-9 border border-gray-200 text-xs"
                                type="text"
                                readonly="true"
                            />
                            <span
                                class="text-red-400 block text-sm mt-1"
                                v-text="form.error.get('no_of_seat')"
                                v-if="form.error.has('no_of_seat')"
                            ></span>
                        </div>
                        <div class="w-full" v-if="userType == 'provider'">
                            <label for="price" class="text-xs text-gray-600 capitalize">
                                Price
                                <span v-if="isPriceRange"
                                >must be({{ parseInt(priceRange.min_price) }} to
                                    {{ parseInt(priceRange.max_price) }} BDT)</span
                                >
                            </label>
                            <input
                                class="text-gray-500 rounded bg-gray-100 mb-4 w-full p-2 border border-gray-200 text-sm"
                                type="text"
                                placeholder=""
                                v-model="form.price"
                            />
                        </div>
                    </div>
                </div>


                <div class="flex justify-center">
                    <p class="text-red-600">{{ errorMessage }}</p>
                </div>

                <div class="flex justify-center">
                    <button
                        class="bg-primary rounded capitalize h-10 w-full  mt-4 hover:bg-red-700 transition duration-400  py-2"
                        :class="submitClicked ? 'cursor-not-allowed': ''"
                        :disabled="submitClicked"
                        @click.prevent="showSecondPage">
                        <span class="text-gray-white capitalize text-lg">
                            {{ $t("triCreateForm.create") }}
                        </span>
                    </button>
                </div>
            </div>

            <div v-show="secondPage">
                <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 class="text-lg leading-6 font-medium text-green-500" id="modal-title">
                        সকল তথ্য কি সঠিক ?
                    </h3>

                    <div class="mt-2">
                        <p class="text-sm text-gray-500">
                            {{ $t("triCreateForm.pickupLocation") }} : {{ form.pickup_full_address}}
                        </p>
                        <p class="text-sm text-gray-500">
                            {{ $t("triCreateForm.destinationLocation") }} : {{ form.destination_full_address }}
                        </p>
                        <p class="text-sm text-gray-500">
                            {{ $t("triCreateForm.journeyDate") }} : {{ formattedDate(form.journey_date) }}
                        </p>
                        <p class="text-sm text-gray-500">
                            {{ $t("triCreateForm.journeyTime") }} : {{ formattedTime(form.journey_time) }}
                        </p>
                        <p v-if="form.type == 'round'" class="text-sm text-gray-500">
                            {{ $t("triCreateForm.returnDate") }} : {{ formattedDate(form.return_date) }}
                        </p>
                        <p v-if="form.type == 'round'" class="text-sm text-gray-500">
                            {{ $t("triCreateForm.returnTime") }} : {{ formattedTime(form.return_time) }}
                        </p>
                        <p v-if="form.type" class="text-sm text-gray-500">
                            {{ $t("triCreateForm.type") }} : {{ form.type }}
                        </p>
                        <p class="text-sm text-gray-500">
                            {{ $t("triCreateForm.carType") }} : {{ form.car_type }} {{ form.no_of_seat }} সিট
                        </p>
                        <p v-if="form.price" class="text-sm text-gray-500">
                            {{ $t("triCreateForm.price") }} : {{ form.price }}
                        </p>
                        <p v-if="form.trip_note" class="text-sm text-gray-500">
                            {{ $t("triCreateForm.note") }} ({{ $t("optional") }}) : {{ form.trip_note }}
                        </p>
                    </div>
                </div>
                <div class="px-4 py-3 flex flex-row-reverse space-x-1">
                    <button
                        class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 ml-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        :class="submitClicked ? 'cursor-not-allowed' : ''"
                        :disabled="submitClicked"
                        type="submit">
                        <span v-if="form.loading" class="mdi mdi-cached inline-block loader mr-2"></span>
                        {{ $t("triCreateForm.yes") }}
                    </button>

                    <button class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                            @click.prevent="secondPage=!secondPage">
                        <span class="mdi mdi-arrow-left inline-block mr-2"></span>
                        {{ $t("triCreateForm.back") }}
                    </button>
                </div>
            </div>
            <div>
                <Loader :show="showLoader" />
            </div>
        </form>
    </div>
</template>

<script>
import SearchableDropdown from "@/components/atom/SearchableDropdown";
import Datepicker from "@/components/atom/Datepicker";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import Form from "@/services/form";
import {mapGetters, mapMutations} from "vuex";
import userMixin from "@/mixins/userMixin";
import fotmatTimeMixin from "@/mixins/fotmatTimeMixin";
import GeoSelector from "@/components/atom/GeoSelector.vue";
import GeoSelectorWithoutDivisionUnion from "@/components/atom/GeoSelectorWithoutDivisionUnion";
import PriceManger from "@/services/module/price";
import {inject} from "vue";
import {fetchDistrictWithoutDivision} from "@/services/module/api.geolocation";
import axios from 'axios';
import moment from 'moment';
import Loader from "@/components/atom/LoadingComponent";
import address from "@/components/atom/Address.vue";
import CustomDate from "@/components/atom/CustomDate.vue";

export default {
    name: "TripCreateComponent",
    mixins: [userMixin, fotmatTimeMixin],
    components: {
        SearchableDropdown,
        Datepicker,
        VueGoogleAutocomplete,
        GeoSelector,
        GeoSelectorWithoutDivisionUnion,
        Loader,
        CustomDate
    },
    setup() {
        //provided form app component
        const showError = inject("showError");
        const showSuccess = inject("showSuccess");
        return { showError, showSuccess };
    },
    computed: {
        address() {
            return address
        },
        isMyTripage() {
            return this.$route.name == "RequestTrips";
        },

        timeListItems() {
            let nowTime = moment().add(1, 'hours').format('HH:mm');
            if (this.form.journey_date === moment().format('YYYY-MM-DD')) {
                return this.timeList.filter((item) => {
                    return item.value > nowTime;
                });
            } else {
                return this.timeList;
            }
        }
    },
    props: {
        checkBoxlabelColor: {
            default: "text-gray-100",
        },
        columnStyle: {
            default: "flex flex-wrap lg:space-x-5",
        },
        type: {
            default: 'modal'
        }
    },
    data() {
        return {
            fields: [
                "address_components",
                "geometry",
                "type",
                "name",
                "formatted_address",
                "adr_address",
                "place_id",
            ],
            secondPage: false,
            showLoader: false,
            submitClicked: false,
            isPickupSelected: false,
            isDestinationSelected: false,
            showPickupRecentSearches: false,
            showDestinationRecentSearches: false,
            recentSearches: [],
            isPriceRange: false,
            priceManager: new PriceManger(),
            loading: false,
            journey_date: "",
            journey_time: "",
            return_date: "",
            return_time: "",
            journeyDateConfig:{
                dateFormat: "Y-m-d",
                minDate: "today",
                maxDate: new Date().fp_incr(4),
                disableMobile: "true"
            },
            timeConfig:{
                enableTime: true,
                noCalendar: true,
                dateFormat: "h:i K",
                minuteIncrement: 5,
            },
            returnDateConfig:{
                dateFormat: "Y-m-d",
                minDate: "today",
                maxDate: new Date().fp_incr(4),
                disableMobile: "true"
            },
            priceRange: {},
            cars: [],
            provider_cars: [],
            carLoading: false,
            noCarMessage: "Car is busy",
            form: new Form({
                pickup_full_address: "",
                pickup_division: "",
                destination_full_address: "",
                destination_division: "",
                journey_date: "",
                journey_time: "",
                created_by: "",
                car_id: "",
                car_type:"",
                return_date: "",
                return_time: "",
                price: "",
                status: "",
                no_of_seat: "",
                type: "oneway",
                pickup_district_id: "",
                pickup_sub_district_id: "",
                destination_district_id: "",
                destination_sub_district_id: "",
                pickup_specific_area: "",
                destination_specific_area: "",
                trip_note: "",
                distance: "",
                duration: "",
                pickup_lat: "",
                pickup_long: "",
                destination_lat: "",
                destination_long: "",
            }),
            errorMessage: "",
            noCar: false,
            allDistrictList: [],
            startLocation: null,
            endLocation: null,
            timeList: [
                {
                    time: "রাত ১২:০০ টা",
                    value: "00:00:00",
                },

                {
                    time: "রাত ০১:০০ টা",
                    value: "01:00:00",
                },

                {
                    time: "রাত ০২:০০ টা",
                    value: "02:00:00",
                },

                {
                    time: "রাত ০৩:০০ টা",
                    value: "03:00:00",
                },

                {
                    time: "রাত ০৪:০০ টা",
                    value: "04:00:00",
                },

                {
                    time: "সকাল ০৫:০০ টা",
                    value: "05:00:00",
                },

                {
                    time: "সকাল ০৬:০০ টা",
                    value: "06:00:00",
                },

                {
                    time: "সকাল ০৭:০০ টা",
                    value: "07:00:00",
                },

                {
                    time: "সকাল ০৮:০০ টা",
                    value: "08:00:00",
                },

                {
                    time: "সকাল ০৯:০০ টা",
                    value: "09:00:00",
                },

                {
                    time: "সকাল ১০:০০ টা",
                    value: "10:00:00",
                },

                {
                    time: "দুপুর ১১:০০ টা",
                    value: "11:00:00",
                },

                {
                    time: "দুপুর ১২:০০ টা",
                    value: "12:00:00",
                },

                {
                    time: "দুপুর ০১:০০ টা",
                    value: "13:00:00",
                },

                {
                    time: "দুপুর ০২:০০ টা",
                    value: "14:00:00",
                },

                {
                    time: "দুপুর ০৩:০০ টা",
                    value: "15:00:00",
                },

                {
                    time: "বিকাল ০৪:০০ টা",
                    value: "16:00:00",
                },

                {
                    time: "বিকাল ০৫:০০ টা",
                    value: "17:00:00",
                },

                {
                    time: "বিকাল ০৬:০০ টা",
                    value: "18:00:00",
                },

                {
                    time: "সন্ধ্যা ০৭:০০ টা",
                    value: "19:00:00",
                },

                {
                    time: "রাত ০৮:০০ টা",
                    value: "20:00:00",
                },

                {
                    time: "রাত ০৯:০০ টা",
                    value: "21:00:00",
                },

                {
                    time: "রাত ১০:০০ টা",
                    value: "22:00:00",
                },

                {
                    time: "রাত ১১:০০ টা",
                    value: "23:00:00",
                },

            ],
        };
    },

    methods: {
        ...mapMutations({
            setDialog: "setDialog",
            setTrip: "trip/setTrip",
        }),

        showSecondPage(){
            if (this.formValidate()){
                this.secondPage = true;
            }
        },

        getCarInfo(){
            if(Array.isArray(this.cars) && this.cars.length == 0) {
                this.noCar = true
            }
        },

        formattedDate(dateString) {
            return moment(dateString).format('DD/MM/YYYY');
        },
        formattedTime(timeString) {
            return moment(timeString, 'HH:mm').format('h:mm A');
        },

        formValidate(){
            this.loading = true
            if (this.userType === 'customer' && this.form.trip_note) {
                const regex = /(\+?8801[2-9]\d{8})|(01[2-9]\d{8})|([1-9]\d{9})|([\u09E6-\u09EF]{10})/g;
                const mobileNumbers = this.form.trip_note.match(regex);
                if (mobileNumbers && mobileNumbers.length > 0) {
                    this.form.trip_note = this.form.trip_note.replace(regex, '');
                }
            }

            if (!this.isPickupSelected || !this.isDestinationSelected) {
                this.showError(this.$t("tripValidation.selectLocation"));
                this.loading = false
                return;
            }

            let checkPrice = this.checkPrice();
            if (this.userType === 'provider' && !checkPrice) {
                this.showError("Price must be between " + parseInt(this.priceRange.min_price) + " to " + parseInt(this.priceRange.max_price) + " BDT");
                this.loading = false
                return;
            }

            if (!this.form.journey_date || !this.form.journey_time) {
                this.showError(this.$t("tripValidation.journeyDateTime"));
                this.loading = false
                return;
            }

            let journeyDateTime = new Date(this.form.journey_date + ' ' + this.form.journey_time);
            let currentDateTime = new Date();
            currentDateTime.setHours(currentDateTime.getHours() + 1);
            if (journeyDateTime <= currentDateTime) {
                this.showError(this.$t("tripValidation.journeyDateTimeValidation"));
                this.loading = false
                return;
            }

            if (this.form.type === 'round') {
                if (!this.form.return_date || !this.form.return_time) {
                    this.showError(this.$t("tripValidation.returnDateTime"));
                    this.loading = false
                    return;
                }

                let journeyDateTime = new Date(this.form.journey_date + ' ' + this.form.journey_time);
                let returnDateTime = new Date(this.form.return_date + ' ' + this.form.return_time);
                if (returnDateTime <= journeyDateTime) {
                    this.showError(this.$t("tripValidation.returnDateTimeValidation"));
                    this.loading = false
                    return;
                }
            }

            if(this.userType === 'customer' && !this.form.car_type){
                this.showError(this.$t("tripValidation.selectCarType"));
                this.loading = false
                return;
            }
            if(this.userType === 'provider' && !this.form.price){
                this.showError(this.$t("tripValidation.price"));
                this.loading = false
                return;
            }
            this.form.created_by = this.userType;
            if(this.form.car_type == 'sedan car'){
                this.form.no_of_seat = 4
            }
            if(this.form.car_type == 'mini micro'){
                this.form.no_of_seat = 7
            }
            if(this.form.car_type == 'micro'){
                this.form.no_of_seat = 10
            }
            return true;
        },

        async submit() {
            this.loading = true;
            this.submitClicked = true;
            try {
                let submitResponse = await this.form.post("/trip/store");
                this.handleSubmit(submitResponse);
            } catch (err) {
                if (err.response) {
                    if (err.response.status == 500)
                        this.showError("Something went wrong");
                }
                this.loading = false;
                this.submitClicked = false;
            }
        },

        handleSubmit(submitResponse) {
            if (submitResponse.success) {
                this.updateTripList(submitResponse.data);
                this.showSuccess(submitResponse.message);
                this.loading = false
                this.secondPage = false
                this.showLoader = true
                this.setDialog(false);
                setTimeout(() => {
                    //this.$router.push({ name: "TripBidding", params: { slug: submitResponse.data.id } });
                    this.$router.push({ name: "Home" });
                    this.submitClicked = false;
                }, 2000);
            } else {
                this.errorMessage = submitResponse.message;
            }
        },
        checkPrice() {
            if (this.isPriceRange) {
                return (
                    this.form.price >= parseInt(this.priceRange.min_price) &&
                    this.form.price <= parseInt(this.priceRange.max_price)
                );
            }
            if (!this.isPriceRange) return true;
        },
        updateTripList(data) {
            if (this.isMyTripage) {
                setTimeout(() => {
                    this.setTrip(data);
                }, 2000);
                if (this.$route.name == "RequestTrips") {
                    window.location.reload();
                }
            }
        },
        fetchCar() {
            if (this.userType == "provider") {
                this.form.get("/provider/index-idle-cars", {}).then((res) => {
                    this.cars = res.data;
                });
                this.carLoading = true
                this.form.get("/provider/index-cars", {}).then((res) => {
                    this.provider_cars = res.data;
                    this.carLoading = false
                });
            }
        },
        async fetchPriceRange(target_pick_district, target_dest_district) {
            let res = await this.priceManager.getPriceRange(
                `/price/type/district_district/from/${target_pick_district}/to/${target_dest_district}`
            );
            if (res.data) {
                this.priceRange = res.data;
                this.isPriceRange = true;
            }
            if (!res.data) {
                this.priceRange = {};
                this.isPriceRange = false;
            }
        },
        onCarChange(e) {
            let seat = this.provider_cars.filter((i) => i.car_id == e.target.value)[0]
                .number_of_seat;
            this.form.no_of_seat = seat;
        },

        journeyDateChanged(date) {
            this.form.journey_date = date;
            console.log(date)
        },
        returnDateChanged(date) {
            this.form.return_date = date;
        },


        /**
         * When the location found
         * @param {Object} addressData Data of the found location
         * @param {Object} placeResultData PlaceResult object
         * @param {String} id Input container ID
         */
        getAddressData: function (addressData, placeResultData, id) {

            console.log(addressData, placeResultData);

            let district = addressData.administrative_area_level_2.split(" ")[0];
            //encode district name for bangle district name because of bangle district name is not working without encode
            let encodedDistrict = encodeURIComponent(district);
            console.log(encodedDistrict)

            let districtDetails = this.allDistrictList.filter((item) => {
                //this regex is for Cox's because ' this character is not working without regex
                let regex = new RegExp(`^${district.replace(/'/g, "['’]")}\\b`, "i");
                return item.name.bn ===  decodeURIComponent(encodedDistrict) || regex.test(item.name.en)
            })

            console.log(districtDetails);


            if (id === "pickup") {
                this.form.pickup_full_address = placeResultData.name +
                    (addressData?.route ? ", " + addressData.route : "") +
                    (addressData?.locality ? ", " + addressData.locality : "");

                this.form.pickup_district_id = districtDetails[0].id;
                this.form.pickup_division = addressData.administrative_area_level_1.split(" ")[0]
                this.isPickupSelected = true;
                this.startLocation = {
                    lat: placeResultData.geometry.location.lat(),
                    lng: placeResultData.geometry.location.lng()
                };

                this.form.pickup_lat = placeResultData.geometry.location.lat();
                this.form.pickup_long = placeResultData.geometry.location.lng();
            }
            if (id === "destination") {
                this.form.destination_full_address = placeResultData.name +
                    (addressData?.route ? ", " + addressData.route : "") +
                    (addressData?.locality ? ", " + addressData.locality : "");

                this.form.destination_district_id = districtDetails[0].id;
                this.form.destination_division = addressData.administrative_area_level_1.split(" ")[0]
                this.isDestinationSelected = true;
                this.endLocation = {
                    lat: placeResultData.geometry.location.lat(),
                    lng: placeResultData.geometry.location.lng()
                };
                this.form.destination_lat = placeResultData.geometry.location.lat();
                this.form.destination_long = placeResultData.geometry.location.lng();
            }

            if (this.isPickupSelected && this.isDestinationSelected) {
                this.fetchPriceRange(this.form.pickup_district_id, this.form.destination_district_id)
            }
            this.calculateDistance();

            /*let searchStore = {
                full_address: this.form.pickup_full_address,
                district_id: this.form.pickup_district_id,
                division: this.form.pickup_division,
                lat: placeResultData.geometry.location.lat(),
                lng: placeResultData.geometry.location.lng()
            }
            let recentSearches = localStorage.getItem("recentSearches");
            recentSearches = recentSearches ? JSON.parse(recentSearches) : [];

            const isDuplicate = recentSearches.some((store) => {
                return store.lat === searchStore.lat && store.lng === searchStore.lng;
            });

            if (!isDuplicate) {
                recentSearches.push(searchStore);
                localStorage.setItem("recentSearches", JSON.stringify(recentSearches));
            }*/
        },
        async districtList() {
            let district = await fetchDistrictWithoutDivision();
            this.allDistrictList = district.data;
        },

        //calculate distance between two location using lat and lng
        calculateDistance() {
            if (this.startLocation && this.endLocation) {
                const proxyUrl = 'map/directions';
                axios.get(proxyUrl, { params: { origin: this.startLocation, destination: this.endLocation } })
                    .then(response => {
                        if (response.data.status === 'OK' && response.data.routes.length > 0) {
                            this.form.distance = response.data.routes[0].legs[0].distance.value; // Distance meters
                            this.form.duration = response.data.routes[0].legs[0].duration.value; // Duration in second
                            console.log("distance: " , this.form.distance)
                            console.log("duration: " , this.form.duration)
                        } else {
                            this.distance = 'Unable to calculate distance.';
                        }

                    })
                    .catch(error => {
                        console.error('Error calculating distance:', error);
                        this.distance = 'Error calculating distance.';
                    });
            }
        },
        clearInput(id) {
            if (id === "pickup") {
                this.form.pickup_full_address = "";
                this.isPickupSelected = false;
                this.$refs.address.clear();
            }
            if (id === "destination") {
                this.form.destination_full_address = "";
                this.isDestinationSelected = false;
                this.$refs.address1.clear();
            }
        },
        setLocation(id) {
            console.log("id: " , id)
        },
    },
    mounted() {
        this.districtList();
        this.fetchCar();
        this.form.journey_date = moment().format("YYYY-MM-DD");
        console.log(this.form.journey_date)
        //this.recentSearches = JSON.parse(localStorage.getItem("recentSearches")) || [];
    },
    watch: {
        cars: function(){
            this.getCarInfo()
        }
    }
};
</script>

<style>
.pac-container .pac-item .pac-item-query .pac-matched {
    color: black !important;
}
.input-wrapper {
    position: relative;
}

.input-wrapper .remove{
    position: absolute;
    top: 60%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
}
</style>