<template>
	<div class="opacity-90 hover:opacity-100 flex border border-gray-300 bg-white shadow-md hover:shadow-lg transition duration-300 rounded h-fit sm:p-5 p-2">
		<div>
			<span class="mdi sm:px-10 px-2 text-5xl text-gray-400" :class="`mdi-${icon}`"></span>
		</div>
		<div>
            <slot></slot>
			<p v-if="title" class="capitalize text-xl font-semibold text-primary">{{ title }}</p>
			<p v-if="content" class="text-lg text-gray-600 flex flex-wrap">{{ content }}</p>
		</div>
	</div>
</template>

<script>
	export default {
    props: ['title', 'content', 'icon'],
		name: 'Addresss',

	}
</script>