<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <transition name="fade">
    <div class="fixed z-10 inset-0 overflow-y-auto" v-if="showDialog" style="background: rgba(0,0,0,.5)">
        <div
        class="flex items-end justify-center min-h-screen pt-4 md:px-4 px-1 pb-20 text-center sm:block sm:p-0"
        >
        <div
            @click="setDialog(false)"
            class="fixed inset-0 transition-opacity"
            aria-hidden="true"
        >
            <div class="absolute inset-0"></div>
        </div>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true" >&#8203;</span>
        <div 
        class="modal-rtb max-w-full inline-block align-bottom border-b-8 border-primary bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
        >
            <div class="bg-white md:px-4 px-2 pt-5 pb-4 sm:p-6 sm:pb-4">
            <slot></slot>
            </div>
        </div>
        </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
export default {
  name: "Dialog",
  computed: mapGetters({
        showDialog: "showDialog"
    }),
  methods: mapMutations({
      setDialog: "setDialog"
    })
};
</script>

<style>
  .modal-rtb {
    width: 460px;
  }
</style>