const state = () => ({
	drivers: []
})

const getters = {
	getDrivers: state => state.drivers.data,
}

const mutations = {
	setDrivers: (state, payload) => state.drivers = payload,
}

export default {
	namespaced: true,
	state,
	getters,
	mutations
}