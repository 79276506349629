<template>
    <div>
        <h4 :class="[subtitleCase, subtitleStyle]">{{ subtitle }}</h4>
        <h3 :class="[titleStyle, titleCase]">{{ title }}</h3>
    </div>
</template>

<script>
  export default {
    name: 'TitleSubtitle',
    components:{

    },
    props:{
        title: {
            default: 'this is Section Title'
        },
        titleStyle: {
            type: String,
            default: 'text-2xl font-bold text-red-500 text-center'
        },
        titleCase: {
            default: 'capitalize'
        },
        subtitle: {
            default: 'the subtitle'
        },
        subtitleStyle: {
            default: 'text-sm text-bluishBlack text-center mb-2'
        },
        subtitleCase: {
            default: 'uppercase'
        }
    },
    data(){
      return{

      }
    },
    computed:{

    },
    watch:{

    },
    methods:{

    }
  }
</script>