/* eslint-disable no-console */

// import { register } from 'register-service-worker'

// if (process.env.NODE_ENV === 'production') {
//   register(`${process.env.BASE_URL}pusher-service-worker.js`, {
//     ready () {
//     },
//     registered () {
//     },
//     cached () {
//     },
//     updatefound () {
//     },
//     updated () {
//     },
//     offline () {
//     },
//     error (error) {
//     }
//   })
// }




// new one 

/* eslint-disable no-console */
import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {

  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      console.log('Service worker has been registered in PWA.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    updated () {
     /*document.getElementById('refresh').innerHTML = 'New content is available; please <a onclick="document.location.reload(true)">refresh to update</a>'*/
      console.log('New content is available please refresh.')
      caches.keys().then(function(names) {
        for (let name of names) caches.delete(name);
      });
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
