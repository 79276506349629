<template>
    <div class="slides px-0 relative">
<!--        <span
            @click="gotoPrev"
            class="left-arrow m-2 p-1 text-lg"
            :class="leftArrowClasses"
        > 
            {{ leftIcon }}
        </span>
        <span
            class="right-arrow m-2 p-1"
            @click="gotoNext" 
            :class="rightArrowClasses"
        >
            {{ rightIcon }}
        </span>-->

        <div ref="wrapper" class="flex justify-between mb-3">
            <slot name="top"></slot>

        </div>
        <div class="sideNav">
            
        </div>
        <div
            :style="{width: innerWidht + 'px', marginLeft: '-' + slidesInnerMarginLeft + 'px'}"
            class="slider-inner flex"
        >
        <div 
            :style="{width: singleWidth + 'px'}"
            :class="singleSlideStyle" 
            v-for="(slide, i) in slides"
            :key="i"
        >
            <slot :slide="slide"></slot>
        </div>
            
        </div>

        <div class="flex justify-center mt-2">
            <div
                class="rounded-full flex justify-center items-center w-8 h-8 mr-2 bg-gray-400 hover:bg-gray-200 hover:shadow-xl transition-all duration-500 ease-in-out shadow-lg"
                @click="gotoPrev"
            >
                <span class="mdi mdi-arrow-left mdi-24px"></span>
            </div>
            <div
                class="rounded-full flex justify-center items-center w-8 h-8 bg-gray-400 hover:bg-gray-200 hover:shadow-xl transition-all duration-500 ease-in-out shadow-lg"
                @click="gotoNext"
            >
                <span class="mdi mdi-arrow-right mdi-24px"></span>
            </div>
        </div>
        <!--<div class="navigation">
            <span @click="gotoPrev">Prev</span>
            <span @click="gotoIndex(i)" class="nav-number" :key="i" v-for="(slide, i) in slides">{{ i +  1 }}</span>
            <span @click="gotoNext">Next</span>
        </div>-->
    </div>
</template>

<script>
  export default {
    name: 'Carousel',
    components:{
        // TextWithIcon,
    },
    props: {
        responsive: {
            default: [
                {
                    width: 0,
                    item: 1
                },{
                    width: 768,
                    item: 2
                },{
                    width: 1024,
                    item: 3
                },{
                    width: 1280,
                    item: 4
                },
            ]
        },
        slides: {
            required: true,
            type: Array
        },
        singleSlideStyle:{
            type: String,
            default: 'border-4 border-transparent'
        },
        leftIcon: {
            default: '<'
        },
        rightIcon: {
            default: '>'
        },
        leftArrowClasses: {
            type: String,
            default: 'rounded-l border text-gray-600 font-semibold cursor-pointer opacity-50 hover:opacity-90 transition duration-300'
        },
        rightArrowClasses: {
            type: String,
            default: 'rounded-r border text-gray-600 font-semibold cursor-pointer opacity-50 hover:opacity-90 transition duration-300'
        }
    },
    data(){
      return{
        itemsPerSlide: 0,
        innerWidht: 0,
        singleWidth: 0,
        currentIndex: 0,
      }
    },
    
    computed:{
        slidesInnerMarginLeft() {
            return this.currentIndex * this.singleWidth;
        },
    },
    
    mounted(){
        let diviceWidth= this.$refs.wrapper.clientWidth;
        
        for(var i=0; i<this.responsive.length; i++){
             if(diviceWidth > this.responsive[i].width){
                this.itemsPerSlide = this.responsive[i].item;
            }
        }
        
        let singleWidth = diviceWidth/this.itemsPerSlide;
        this.singleWidth = singleWidth;
        this.innerWidht =  singleWidth * this.slides.length;

    },
    methods:{
        gotoPrev() {
            if(this.currentIndex == 0) {
                return
            }
            this.currentIndex = this.currentIndex - 1
        },

        gotoNext() {
            if(this.currentIndex >= this.slides.length - this.itemsPerSlide) {
                return
            }
            this.currentIndex++
        },

        gotoIndex(i){
            this.currentIndex = i+2;
        }
    }
  }
</script>

<style>
    .slides{
        overflow: hidden;
    }

    .slider-inner{
        transition: margin 0.6s ease-out;
    }

</style>