<template>
	<div class="flex justify-start items-center">
		<div class="relative bg-gray-300 rounded-full overflow-hidden">
			<img v-if="isValidImage" :src="image" :class="[imageStyle, heightWeight]" />

			<img
				v-else
				:src="`https://ui-avatars.com/api/?name=${alt}&color=7F9CF5&background=EBF4FF`"
				:class="[imageStyle, heightWeight]"
			/>
			<div
				class="h-10 md:w-40 w-32 cursor-pointer absolute bottom-0"
			>
				<img
					class="w-8 mt-1 block mx-auto"
					src="/images/icons/camera-black.svg"
					alt=""
				/>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "RoundImage",
	props: {
		image: {
			type: String,
			default: "",
		},
		alt: {
			required: false,
			type: String,
			default: "rt",
		},
		heightWeight: {
			type: String,
			default: "w-32 h-32",
		},

		imageStyle: {
			default: "shadow-md rounded-full",
		},
	},
    computed: {
        isValidImage() {
            const validExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];
            const extension = this.image.split('.').pop().toLowerCase();
            return validExtensions.includes(extension);
        }
    }
};
</script>
