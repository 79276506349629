<template>
  <div class="shadow-sm bg-white animate-pulse">
    <div class="flex justify-between  rounded">
      <div class="h-5 bg-gray-200 w-40"></div>
      <div class="h-5 bg-gray-200 w-24"></div>
    </div>
    <div class="p-3">
      <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6">
        <div v-for="n in childNumber" :key="n" class="mb-4">
          <div class="h-4 w-3/4 bg-gray-200 mb-2"></div>
          <div class="h-3 w-3/5 bg-gray-200"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SkeletonLoader',
    props: {
        childNumber: {
          type: Number,
          default: 6
        }
    }
  }
</script> 