const state = () => ({
    trips: [],
    singleTrip: {}
})

const getters = {
	getTrips: (state) => state.trips,
	getSingleTrip: state => state.singleTrip,
	getConfirmBookingStatus: (state) => (data) => {
		let trip  = state.trips.find(item => item.id === data.id);
		if(trip) {
			let confirmBooking = trip[data.type].find(item => item.status == 'confirmed');
			return confirmBooking ? true : false;
		}
		return false;
	},
	getConfirmBookingPrice: (state) => (data) => {
		let trip  = state.trips.find(item => item.id === data.id);
		if(trip) {
			let confirmBooking = trip[data.type].find(item => item.status == 'confirmed');
			return confirmBooking ? confirmBooking.fixed_price : '';
		}
		return '';
	},
}

const mutations = {
	setTrips: (state, payload) => state.trips = [...state.trips, ...payload],
	setTrip: (state, payload) => state.trips.unshift(payload),
	setSingleTrip: (state, payload) => state.singleTrip = payload,
	emptyExistingTrips: (state) => state.trips = [],
	updateBookingofSingleTrip: (state, payload) => {
		state.singleTrip[payload.type][0].status = payload.status;
	},
	setBookingToSingleTrip: (state, payload) =>{
		state.singleTrip[payload.key].push(payload.data);
	},
	updateTripListAfterCancel: (state, trip) => {
		let index = state.trips.indexOf(trip);
		state.trips.splice(index, 1);
	},
	updateTripAfterStatusChange: (state, payload) => {
		let index = state.trips.indexOf(payload.trip);
		state.trips[index].status = payload.status;
	},
	updateFixedPrice: (state, payload) => {
		const {id, bookingId, type, status} = payload;
		let filteredTrip = state.trips.filter(trip => trip.id == id);
		let trip = filteredTrip[0];
		let booking = trip[type].filter(booking => booking.id == bookingId);
		booking[0].status = status;
		booking[0].fixed_price = fixedPrice;
	},
	updateBookingAfterConfirm: (state, payload) => {
		const {type, status} = payload;
		let triparr = state.trips.filter(trip => trip.id == payload.id);
		let trip = triparr[0];
		let booking = trip[type].filter(booking => booking.id == payload.bookingId);
		booking[0].status = status;
	},
	

	/*
		when there is a new booking
		booking information will be pushed
		@param {payload} should contain trip id, booking type, data
	*/
	pushBookingInfo: (state, payload, commit) => {
		let trip = state.trips.filter(trip => trip.id == payload.tripId);
		if(trip.length) {
			let tripIndex =  state.trips.indexOf(trip[0]);
			 if(tripIndex !== 0) {
			 	commit('tripShort', tripIndex);
			 }
		}

	},

	/*
		take a trip top of the array
		@param {index} index of trip in trips array
	*/
	tripShort: (state, index) => {
		let removed = state.trips.splice(index, 1);
		state.trips.splice(0, 0, removed);
	}

}

export default {
	namespaced: true,
	state,
	getters,
	mutations
}