<template>
    <div 
        class="md:hidden flex justify-between rounded px-2"
        :class="cardBg"
    >
        <div class="grid">
            <!-- <div class="flex space-x-2 items-center">
                <i :class="seatIcon" class="mdi text-primary text-xl"></i>
                <p class="text-gray-500 text-sm">{{ total }} {{seatOrPassenger}}</p>
            </div> -->
            <div class="flex space-x-2 items-center">
                <span class="mdi mdi-car text-primary"></span>
                <p class="text-gray-500 text-sm capitalize">{{ carType +' ' + getTripCarType(carType)  }}</p>
            </div>
        </div>
        <div class="flex space-x-2 items-center">
            <i class="mdi mdi-road-variant text-primary text-2xl"></i>
            <p class="text-gray-500 text-sm capitalize">{{ type == 'oneway' ?  $t("one_way") : $t("round_trip") }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SeatAndTripViewer',
    props: {
        seatIcon: {
            default: 'mdi-seat-passenger'
        },
        cardBg: {
            default: 'bg-red-50'
        },
        seatOrPassenger: {
            type: String
        },
        carType:{
            type:String
        },
        total: {
            type: Number
        },
        type: {
            type: String
        }
    },

    methods: {
        getTripCarType(type){
			if(type == 'mini micro'){
				return '(7 seats)';
			}
			if(type == 'sedan car' || type == 'premium car'){
				return '(4 seats)';
			}
            if (type == 'premium car') {
                return '(4 seats)';
            }
			if(type == 'micro'){
				return '(10 seats)';
			}
		}
    }
}
</script>
