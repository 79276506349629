<template>
	<Header
		:class="{ 'hidden': !showNavbar }"
		class="fixed top-0 z-10 w-full border border-b-1 border-gray-200"
		:logo="siteData.logo"
	/>
	<div class="sm:mt-16 mt-8" :class="$route.name ==='Feed' ? 'md:pt-10 pt-0':''" style="min-height: calc(100vh - 100px)">
		<slot />
	</div>
	<Footer :footerLogo="siteData.footer_logo"/>
	<Dialog>
		<component :is="component"></component>
	</Dialog>
	<Toaster />
</template>

<script>
import layoutImports from './layoutImports';
import { mapGetters, mapMutations } from 'vuex';
import {getSiteData} from '@/services/module/content';
import userMixin from "@/mixins/userMixin";
import updateViaPusher from "@/mixins/updateViaPusher";
import notificationMixin from "@/mixins/notificationMixin";
import {configureEcho, subscribeToPusherCustomer} from '@/services/pusher'
import {subscribeToPusher} from '@/services/pusher'
import {inject} from "vue";

export default {
  name: "MasterLayout",
  mixins: [userMixin, notificationMixin, updateViaPusher],
  components:layoutImports,

  data() {
  	return {
		showNavbar: true,
		lastScrollPosition: 0
  	}
  },

  computed: {
	  ...mapGetters({
		component: "dialogComponent",
		siteData: "getSiteData"
	}),
  },
    setup() {
        const showToastNotification = inject("showToastNotification");
        return { showToastNotification };
    },
  	

	methods: {
		...mapMutations({
			setSiteData:'setSiteData',
			pushBookingInfo: 'trip/pushBookingInfo',
			setTrips: 'trip/setTrips',
			tripShort: 'trip/tripShort',
			emptyExistingTrips: 'trip/emptyExistingTrips',
			setBookings: 'bookings/setBookings',
		}),

		afterLeave () {
			this.$root.$emit('triggerScroll')
		},


		async showNotification(title, body, url) {
			if(window.Notification && Notification.permission === 'granted') {
				this.displayWebNotification(title, body, url);
			}
            else if(!window.Notification && Notification.permission === 'granted') {
                await self.registration.showNotification(title, {
                    body: body,
                    icon: '/img/icons/favicon-32x32.png',
                })
            }
			else if(Notification.permission !== "denied") {
				let status = await this.askNotificationPermission();
				if(status) {
					this.displayWebNotification(title, body, url);
				}
			}
		},

		initializeListener(user) {
			if(user?.type === 'provider') {
				window.Echo.private(`ProviderGlobalNotification-${user.id}`)
				.listen('CustomerUpdatedUserBooking', (e) => {

				})
                .listen('\\App\\Events\\Notifications\\NotifyProviderPassengerCreatedNewTrip', (e) => {
                    subscribeToPusher(user?.id);
                    this.showToastNotification('নতুন ট্রিপ রিকোয়েস্ট দেখুন')
                })
				.listen('\\App\\Events\\Notifications\\CustomerConfirmedBookingProvider', (e) => {
                    subscribeToPusher(user?.id);
					this.updateBookingList(e.bookingProviderId, 'booking-provider');
                    this.showToastNotification('কাস্টমার আপনার বিড কনফার্ম করেছে')
				})
				.listen('\\App\\Events\\Notifications\\CustomerConfirmedBookingUser', (e) => {
                    subscribeToPusher(user?.id);
					this.updateRequestTripListAfterBooking(e.bookingUserId, 'bookingUsers');
                    this.showToastNotification('কাস্টমার আপনার বিড কনফার্ম করেছে')
				})
				.listen('CustomerCreatedUserBooking', (e) => {

				})
				.listen('\\App\\Events\\Notifications\\UserCreatedUserBooking', (e) => {
                    //Customer Bid Provider page auto update
                    subscribeToPusher(user?.id);
					this.updateRequestTripListAfterBooking(e.bookingUserId, 'bookingUsers');
                    this.showToastNotification('Your trip has a new bidding')
				})
				.listen('CustomerUpdatedProviderBooking', (e) => {
				});
				return;
			}

			if(user?.type === 'customer') {
				window.Echo.private(`UserGlobalNotification-${user.id}`)
                    .listen('\\App\\Events\\Notifications\\ProviderCreatedProviderBooking', (e) => {
                        //Provider Bid Customer page auto update
                        subscribeToPusherCustomer(user?.id);
                        this.updateRequestTripListAfterBooking(e.bookingProviderId, 'bookingProviders')
                        this.showToastNotification('আপনার ট্রিপে নতুন বিড এসেছে')
                    })
                    .listen('\\App\\Events\\Notifications\\ProviderConfirmedUserBooking', (e) => {
                        this.updateBookingList(e.bookingUserId, 'booking-user')
                        this.showToastNotification('Provider confirmed your bidding')
                    })
                    .listen('\\App\\Events\\Notifications\\ProviderUpdatedUserBooking', (e) => {

                    })
                    .listen('\\App\\Events\\Notifications\\BookingProviderNegotiationPriceUpdated', (e) => {
                    })
                    .listen('\\App\\Events\\Notifications\\ProviderUpdatedBookingUserFixedPrice', (e) => {
                        subscribeToPusher(user?.id);
                        this.updateBookingList(e.bookingUserId, 'booking-user')
                    })
			}
		},

		async fetchSiteData() {
			let data = await getSiteData();
			if(data.success){
				const {banner, logo, footer_logo, social_link} = data.data.setting;
				let links = social_link.value
				this.setSiteData({banner, logo, footer_logo, links})
			}
		},

		onScroll () {
			const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
			if (currentScrollPosition < 0) {
				return
			}
			// Stop executing this function if the difference between
			// current scroll position and last scroll position is less than some offset
			if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 60) {
				return
			}
			//this.showNavbar = currentScrollPosition < this.lastScrollPosition
			this.showNavbar = true
			this.lastScrollPosition = currentScrollPosition
		},

		initializeEcho() {
			let token = localStorage.getItem('token');
			if(token) {
				configureEcho(token);

			}
		}
	},

	mounted() {
		this.initializeEcho();
		this.initializeListener(this.user || null);
		this.fetchSiteData();
		window.addEventListener('scroll', this.onScroll);
	},

	beforeDestroy () {
	  window.removeEventListener('scroll', this.onScroll);
	}
}
</script>