const state = () => ({
	cars: [],
	carModels: []
})

const getters = {
	getCars: state => state.cars, // Without paginate
	getCarModels: state => state.carModels
}

const mutations = {
	setCars: (state, payload) => state.cars = payload,
	setDriver: (state, payload) => state.drivers.push(payload),
	setCarModels: (state, payload) => state.carModels = payload,
}

export default {
	namespaced: true,
	state,
	getters,
	mutations
}