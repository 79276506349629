const state = () => ({
	isLoggedIn: false,
    user: null,
    profile: {}
})

const getters = {
	getUser: state => state.user,
	getUserId: state => state.user.id,
	getProfile: state => state.profile
}

const mutations = {
	setLoginStatus: (state, payload) => state.isLoggedIn = payload,
    setLoggedInUser(state) {
      let userData = localStorage.getItem('trip_auth_user');
      state.user = JSON.parse(userData);
    },

    setProfile: (state, payload) => state.profile = payload
}

export default {
	namespaced: true,
	state,
	getters,
	mutations
}