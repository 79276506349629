import Form from '../form';
const form = new Form;
import {ref, onMounted} from 'vue';

export const fetchDivision = async () => await form.get('select/geo_loc/divisions');
export const fetchDistrict = async (division_id) => await form.get(`select/geo_loc/districts/${division_id}`);
export const fetchDistrictWithoutDivision = async () => await form.get(`select/geo_loc/districts-without-division`);
export const fetchSubDistrict = async (district_id) => await form.get(`select/geo_loc/sub_districts/${district_id}`);
export const fetchUnion = async (sub_district_id) => await form.get(`select/geo_loc/unions/${sub_district_id}`);

export default function getGeoLocation() {
    let selected = ref([]);
    let eventName = ref('initialState');
    let data = ref([]);
    let backup = ref([]);
    let division = ref([]);
    let district = ref([]);
    let fetchProgress = ref(false);

    const getDivision = async () => {
        fetchProgress.value = true;
        let res = await fetchDivision();
        fetchProgress.value = false;
        division.value = res.data;
        data.value = res.data;
        backup.value = res.data;
    }
    const getDistrict = async (division_id) => {
        fetchProgress.value = true;
        let res = await fetchDistrict(division_id);
        fetchProgress.value = false;
        data.value = res.data;
        backup.value = res.data;
    }
    const getDistrictWithoutDivision = async () => {
        fetchProgress.value = true;
        let res = await fetchDistrictWithoutDivision();
        fetchProgress.value = false;
        district.value = res.data;
        data.value = res.data;
        backup.value = res.data;
    }
    const geSubDistrict = async (district_id) => {
        fetchProgress.value = true;
        let res = await fetchSubDistrict(district_id);
        fetchProgress.value = false;
        data.value = res.data;
        backup.value = res.data;
    }
    const getUnion = async (sub_district_id) => {
        fetchProgress.value = true;
        let res = await fetchUnion(sub_district_id);
        fetchProgress.value = false;
        data.value = res.data;
        backup.value = res.data;
    }
    const setSelected = (value) => {
        selected.value.push(value);
    }
    const setInitialState = () => {
        selected.value = [];
        data.value = district.value;
        eventName.value = 'initialState';
    }
    const setDivisionSelectionState = () => {
        selected.value = [];
        data.value = division.value;
        eventName.value = 'onSelectDivision';
    }
    const setDistrictSelectionWithoutDivisionState = () => {
        selected.value = [];
        data.value = district.value;
        eventName.value = 'onSelectDistrictWithoutDivision';
    }
    const filterData = (key) => {
        data.value = backup.value.filter(item => {
            if(item.name.en.toLowerCase().includes(key.toLowerCase())) {
                return item;
            }
        })
    }    

    onMounted(() => {
        // getDivision();
        getDistrictWithoutDivision();
        setInitialState();
    });

    return {
        data,
        eventName,
        selected,
        fetchProgress,
        setSelected,
        getDistrict,
        geSubDistrict,
        getUnion,
        setInitialState,
        setDivisionSelectionState,
        setDistrictSelectionWithoutDivisionState,
        filterData
    }
}