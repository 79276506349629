import {trips, bookings} from '@/services/module/api.pusher';
import {filter} from 'lodash';
const updateViaPusher = {
    computed: {
        routeName() {
            return this.$route.name;
        }
    },

	methods: {
        /**
        * @param {id}, is booking id
        * *
        * @param {containingArrayName}, specify  is which array 
        * will be searched. its either 'bookingUsers'
        * or 'bookingProviders'. array of booking inside triplist response
        */
        async updateRequestTripListAfterBooking(id, containingArrayName) {
            //let response = await trips();
            let query = {
                status: `hold+started+active`,
            }
            let response = await trips(query);
            let trip;
            if(response.success) {
                trip = filter(response.data, {[`${containingArrayName}`]: [{id: id}]});
            }

            // get the trip that store in vuex store and update it with new data from pusher response
            let getTrip = this.$store.getters['trip/getSingleTrip'];
            if(getTrip.id === trip[0].id) {
                this.$store.commit('trip/setSingleTrip', trip[0]);
            }

            if(trip.length > 0) {
                let index = response.data.indexOf(trip[0]);
                let removedTrip = response.data.splice(index, 1);
                removedTrip[0].newAction = true;
                response.data.splice(0, 0, removedTrip[0]);
                this.emptyExistingTrips();
                this.setTrips(response.data);
            }
        },

        async updateBookingList(id, type) {
            let response = await bookings(type);
            let booking;
            if(response.success) {
                booking = filter(response.data, {id: id});
            }

            if(booking.length > 0) {
                let index = response.data.indexOf(booking[0]);
                let removedBooking = response.data.splice(index, 1);
                removedBooking[0].newAction = true;
                response.data.splice(0, 0, removedBooking[0]);
                this.setBookings(response.data);
            }
        },

	}
	

}

export default updateViaPusher;