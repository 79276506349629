<template>
  <div
    class="p-2 relative bg-white rounded-md border-l-8 border-primary shadow-sm w-64 md:w-62 sm:w-54"
  >
    <label for="pickup" class="text-xs text-gray-600 capitalize">
      {{ $t("triCreateForm.pickupLocation") }}
    </label>
    <div class="flex">
      <span class="mdi mdi-car-pickup text-primary pr-2"></span>
      <vue-google-autocomplete
        ref="pickup"
        id="pickup"
        name="pickup"
        classname="text-gray-500 rounded bg-white w-full font-semibold text-gray-600 text-xs"
        placeholder=""
        v-on:placechanged="getPickupData"
        country="bd"
        types="establishment"
      >
      </vue-google-autocomplete>
    </div>

    <span
      class="mdi mdi-map-marker-outline text-gray-400 absolute top-8 right-2"
      v-if="!address"
    ></span>
    <span
      class="text-red-400 block text-xs mt-1"
      v-text="form.error.get('pickup')"
      v-if="form.error.has('pickup')"
    ></span>
  </div>
</template>
<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";
import Form from "@/services/form";

export default {
  components: {
    VueGoogleAutocomplete,
  },
  props: {
    lat: {
      required: true,
    },
    lng: {
      required: true,
    },
    full_address: {
      required: true,
    },
    division: {
      required: true,
    },
    placeHolder: {
      default: false,
    },
    name: {
      default: false,
    },
  },
  data() {
    return {
      form: new Form(),
      address: "",
    };
  },
  methods: {
    getPickupData(pickupData) {
      this.address = pickupData.route;
      let data = {
        lat: pickupData.latitude,
        lng: pickupData.longitude,
        full_address: pickupData.route,
        division: pickupData.locality,
      };

      this.$emit("onClicked", data);
    },
  },
};
</script>
<style>
.pac-container .pac-item .pac-item-query .pac-matched {
  color: black !important;
}
.pac-logo::after {
  display: none !important;
}
.pac-item {
  border-radius: 20px;
  cursor: default;
  padding: 2px 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 30px;
  text-align: left;
  border-top: 1px solid #e6e6e6;
  font-size: 12px;
  color: #999;
}
.pac-icon {
  width: 15px;
  height: 20px;
  margin-right: 7px;
  margin-top: 6px;
  display: inline-block;
  vertical-align: top;
  background-image: url("http://maps.google.com/mapfiles/kml/paddle/red-circle.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
</style>
