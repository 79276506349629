import { defineAsyncComponent } from "vue";

const SignupComponent = defineAsyncComponent(() =>
	import("../molecule/auth/Signup")
);
const LoginComponent = defineAsyncComponent(() =>
	import("../molecule/auth/Signin")
);
const TripCreateForm = defineAsyncComponent(() =>
	import("../molecule/company/TripCreateForm")
);
const CreateDriverForm = defineAsyncComponent(() =>
	import("../molecule/Driver/CreateDriver")
);
const TermsAndConditions = defineAsyncComponent(() =>
	import("../atom/TermsAndCondition")
);
const PrivacyPolicy = defineAsyncComponent(() =>
	import("../atom/PrivacyPolicy")
);
const RentInfo = defineAsyncComponent(() =>
	import("../atom/RentInfo")
);
const TutorialVideo = defineAsyncComponent(() =>
	import("../atom/TutorialVideo")
);

const TripExpireInfo = defineAsyncComponent(() =>
	import("../atom/TripExpireInfo")
);

const OfferModal = defineAsyncComponent(() =>
	import("../atom/OfferModal")
);

const NotExactUser = defineAsyncComponent(() =>
	import("../atom/NotExactUser")
);

import Dialog from "@/components/atom/Dialog";
import Header from "@/components/layout/Header";
import Footer from "@/components/layout/Footer";
import Toaster from "@/components/atom/Toaster";
export default {
	Dialog,
	Header,
	Footer,
	Toaster,
	// Dynamic components for modal.
	SignupComponent,
	LoginComponent,
	TripCreateForm,
	CreateDriverForm,
	TermsAndConditions,
	PrivacyPolicy,
	RentInfo,
	TutorialVideo,
	TripExpireInfo,
	OfferModal,
	NotExactUser,
};
