<template>
  <Header :logo="siteData.logo" />
    <div style="min-height: calc(100vh - 210px)">
      <slot />
    </div>
  <Footer />
  <Toaster />
  <Dialog>
		<component :is="component"></component>
	</Dialog>
</template>

<script>
import layoutImports from "./layoutImports";
import { mapGetters, mapMutations } from 'vuex';
import {getSiteData} from '@/services/module/content'

export default {
  name: "AuthLayout",
  components: layoutImports,

  computed: mapGetters({
    siteData: "getSiteData",
    component: "dialogComponent",
  }),

  methods: {
    ...mapMutations({
      setSiteData:'setSiteData'
    }),

    async fetchSiteData() {
      let data = await getSiteData();
      if(data.success){
        const {banner, logo, social_link} = data.data.setting;
        let links = social_link.value
        this.setSiteData({banner, logo, links})
      }
    }
  },

  mounted() {
    this.fetchSiteData()
  }
};
</script>
