<template>
  <div
    class="p-2 relative bg-white rounded-md border-l-8 border-primary shadow-sm w-64 md:w-62 sm:w-54"
  >
    <label for="pickup" class="text-xs text-gray-600 capitalize">
      Destination
    </label>
    <div class="flex">
      <span class="mdi mdi-bus-marker text-primary pr-2"></span>
      <vue-google-autocomplete
        ref="destination"
        id="destination"
        name="destination"
        classname="text-gray-500 rounded bg-white w-full font-semibold text-gray-600 text-xs"
        placeholder=""
        v-on:placechanged="getDestinationData"
        country="bd"
        types="establishment"
      >
      </vue-google-autocomplete>
    </div>
    <span
      class="mdi mdi-map-marker-outline text-gray-400 absolute top-8 right-2"
      v-if="!address"
    ></span>
    <span
      class="text-red-400 block text-xs mt-1"
      v-text="form.error.get('destination')"
      v-if="form.error.has('destination')"
    ></span>
  </div>
</template>
<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";
import Form from "@/services/form";

export default {
  components: {
    VueGoogleAutocomplete,
  },
  props: {
    lat: {
      required: true,
    },
    lng: {
      required: true,
    },
    full_address: {
      required: true,
    },
    division: {
      required: true,
    },
    placeHolder: {
      default: false,
    },
    name: {
      default: false,
    },
  },
  data() {
    return {
      form: new Form(),
      address: "",
    };
  },
  methods: {
    getDestinationData(destinationData) {
      this.address = destinationData.route;

      let data = {
        lat: destinationData.latitude,
        lng: destinationData.longitude,
        full_address: destinationData.route,
        division: destinationData.locality,
      };

      this.$emit("onClicked", data);
    },
  },
};
</script>
