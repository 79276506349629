import Form from '../form';
export default class TripManager extends Form {
	constructor (data) {
		super(data);
		this.skeletonLoader = false;
		this.bookingLoader = false;
	}

	async trips(queies='') {
		this.skeletonLoader = true;
		let apiEnd = '/trip/index';
        let response = await this.get(`${apiEnd}?status=${queies.status}`);
        this.skeletonLoader = false;
        return response;
	}	

	async singleTrip(queies='') {
		let apiEnd = '/trip/show';
        let response = await this.get(apiEnd, queies);
        return response;
	}		

	async frontTrip() {
		this.skeletonLoader = true;
		let apiEnd = '/front-trip/index';
        let response = await this.get(apiEnd, this.query);
        this.skeletonLoader = false;
        return response;
	}		

	async searchTrip(query) {
		this.skeletonLoader = true;
		let apiEnd = '/front-trip/index';
        let response = await this.get(apiEnd, query);
        this.skeletonLoader = false;
        return response;
	}		

	async bookingsofProviderTrip(apiEnd, queies) {
		this.bookingLoader = true;
		// let apiEnd = '/front-trip/index';
        let response = await this.get(apiEnd, queies);
        this.bookingLoader = false;
        return response;
	}



	showToaster(message){
        this.network.showSuccessMessage(message)
    }
}