import Form from '../form';
export default class PriceManager extends Form {
	constructor (data) {
		super(data);
	}

	async getPriceRange(api) {
		let apiEnd = api
        let response = await this.get(apiEnd);
        return response;
	}	

	
}