<template>
  <!-- component -->

  <div class="relative inline-block text-left ">
    <button
      class="w-64 md:w-60 sm:w-54 px-2 rounded-md border-l-8 border-primary shadow-sm py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none "
      @click="showList = !showList"
    >
      <div class="flex justify-start items-center w-full">
        <span class="mdi mdi-run text-primary pr-2"></span>
        <div class="w-full text-left">
          <p class="text-xs text-gray-500">{{ label }}</p>
          <div class="flex justify-between w-full">
            <p class="font-semibold text-gray-600">
              {{ selected.name || placeholder }}
            </p>
            <span class="mdi mdi-chevron-down text-gray-600"></span>
          </div>
        </div>
      </div>
    </button>
    <SelectBox
      :options="options"
      :showList="showList"
      v-on:selected="validateSelection"
      :disabled="false"
      placeholder="Click Here"
      :maxItem="maxItem"
    />
  </div>
</template>

<script>
import SelectBox from "@/components/atom/SelectBox";
export default {
  name: "SearchableDropdown",
  components: {
    SelectBox,
  },
  props: ["options", "placeholder", "maxItem", "label"],
  data() {
    return {
      showList: false,

      selected: { name: null, id: null },
    };
  },
  methods: {
    validateSelection(selection) {
      this.selected = selection;
      this.showList = false;
    },
  },
};
</script>
