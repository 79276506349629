import Form from '../form';
const form = new Form;

const apiEnd = '/trip/index';
export const trips = async (queies='') => await form.get(`${apiEnd}?status=${queies.status}`);

//export const booking = async (id) => await form.get(`/booking-provider/index?id=${id}`);
/*export const trips = async () => await form.get('/trip/index',{
    status: `hold+started+active`,
});*/

/**
     * bookings fetch bookings of custome and provider
     * @param {type} .for coustomer, it is booking-customer
     * for provider, its booking-provider
 */
export const bookings = async (type) => await form.get(`/${type}/index`)