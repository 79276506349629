<template>
  <component :is="layout">
    <router-view v-slot="{ Component }">
      <transition name="fade">
        <component :is="Component" />
      </transition>
    </router-view>
    <div class="reload-trip-page cursor-pointer hover:bg-green-500" v-if="reload && isHomeOrFeed" @click="reloadPage()">
        <p class="text-white font-extrabold">নতুন ট্রিপ রিকোয়েস্ট দেখুন</p>
        <button type="button">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-refresh-cw"><polyline points="23 4 23 10 17 10"></polyline><polyline points="1 20 1 14 7 14"></polyline><path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15"></path></svg>
        </button>
    </div>
    
  </component>
</template>

<script>
  import { useI18n } from 'vue-i18n';
  import { provide } from 'vue'
  const default_layout = "master";
  import { useToast } from "vue-toastification";
  import { ref } from 'vue'
  export default {
    name: 'App',
    computed: {
    layout() {
        return (this.$route.meta.layout || default_layout) + "-layout";
      },
        isHomeOrFeed() {
            return this.$route.name === "Home" || this.$route.name === "Feed";
        },
    },
    setup() {
      const reload = ref(false)
      const {t } = useI18n;
      const toast = useToast();
      const showError = (message, position='bottom-center') => {
        toast.error(message, {position: position})
      };
      const showSuccess = (message, timeout=5000) => {
        toast.success(message, {timeout: timeout})
      };
      const showToastNotification = (message, timeout=10000) => {
        if(message === 'নতুন ট্রিপ রিকোয়েস্ট দেখুন') {
          reload.value = true
        }
        else {
          toast.success(message, {timeout: timeout})
        }
      };
      const reloadPage = () => {
        window.location.reload();
      };
      provide('showError', showError);
      provide('showSuccess', showSuccess);
      provide('showToastNotification', showToastNotification);
      return {
        t,
        reload,
        reloadPage
      }
    }
  }
</script>

<style>
  @import url('./assets/style/transition.css');
  @import url('./assets/style/app.css');
  @import url('./assets/style/style.css');
  @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Bengali&display=swap');
  .reload-trip-page{
    margin: 0 auto;
    width: calc(100% - 6px);
    max-width: 450px;
    height: 60px;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    align-items: center;
    z-index: 99999;
    font-size: 14px;
    @apply bg-green-400 rounded fixed sm:top-24 top-20 left-0 right-0;
  }
</style>
