export const links = [
	{
		name: 'about us',
		route: '/about-us'
	},
	{
		name: 'contact',
		route: '/contact'
	},
	{
		name: 'terms & condition',
		route: '/terms-conditions'
	},
	{
		name: 'privacy and policy',
		route: '/privacy-policy'
	},
	{
		name: 'contact us',
		route: '/contact'
	}
]