<template>
    <div class="rtb-landing-page mt-24 max-w-3xl mx-auto shadow">
        <div class="swiper-container splash-rtb max-w-md mx-auto rounded-lg">
<!--            <div class="swiper-wrapper">
                <div class="">
                    <swiper
                        :loop="true"
                        :autoplay="{delay: 5000}"
                        :slides-per-view='1'
                        :space-between="10"
                        :pagination="{
                            clickable: true
                        }"
                        :modules="modules"
                    >
                        <swiper-slide
                            v-for="(item, index) in images"
                            class="max-w-full overflow-hidden rounded-sm"
                        >
                            <img :src="item.src" alt="Slide Image" >
                        </swiper-slide>
                    </swiper>
                </div>
            </div>-->
        </div>
        <div class="w-96 mx-auto">
            <img class="rounded mt-2" src="images/feature-provider.jpg" alt="slider">
        </div>

        <div class="flex justify-around gap-2 p-2 w-96 mx-auto">
            <div
                class="w-2/3 py-4 flex flex-col justify-center items-center border border-gray-300 rounded-md cursor-pointer"
                @click="$router.push('/feed')"
            >
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="37" height="36" viewBox="0 0 37 36" fill="none">
                        <path d="M6.125 9C6.125 5.89331 8.64331 3.375 11.75 3.375H27.5C30.6067 3.375 33.125 5.89331 33.125 9V24.75C33.125 27.8567 30.6067 30.375 27.5 30.375H23.5625C23.2641 30.375 22.978 30.4935 22.767 30.7045C22.556 30.9155 22.4375 31.2016 22.4375 31.5C22.4375 31.7984 22.556 32.0845 22.767 32.2955C22.978 32.5065 23.2641 32.625 23.5625 32.625H27.5C31.8493 32.625 35.375 29.0993 35.375 24.75V9C35.375 4.65075 31.8493 1.125 27.5 1.125H11.75C7.40075 1.125 3.875 4.65075 3.875 9V17.4375C3.875 17.7359 3.99353 18.022 4.2045 18.233C4.41548 18.444 4.70163 18.5625 5 18.5625C5.29837 18.5625 5.58452 18.444 5.79549 18.233C6.00647 18.022 6.125 17.7359 6.125 17.4375V9Z" fill="#1A1A1A"/>
                        <path d="M19.0625 9C18.7641 9 18.478 9.11853 18.267 9.32951C18.056 9.54048 17.9375 9.82663 17.9375 10.125C17.9375 10.4234 18.056 10.7095 18.267 10.9205C18.478 11.1315 18.7641 11.25 19.0625 11.25H28.0625C28.3609 11.25 28.647 11.1315 28.858 10.9205C29.069 10.7095 29.1875 10.4234 29.1875 10.125C29.1875 9.82663 29.069 9.54048 28.858 9.32951C28.647 9.11853 28.3609 9 28.0625 9H19.0625ZM17.9375 15.75C17.9375 15.4516 18.056 15.1655 18.267 14.9545C18.478 14.7435 18.7641 14.625 19.0625 14.625H28.0625C28.3609 14.625 28.647 14.7435 28.858 14.9545C29.069 15.1655 29.1875 15.4516 29.1875 15.75C29.1875 16.0484 29.069 16.3345 28.858 16.5455C28.647 16.7565 28.3609 16.875 28.0625 16.875H19.0625C18.7641 16.875 18.478 16.7565 18.267 16.5455C18.056 16.3345 17.9375 16.0484 17.9375 15.75Z" fill="#1A1A1A"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.58825 21.6551C4.99437 20.4654 6.12331 19.6875 7.36812 19.6875H15.0069C16.2517 19.6875 17.3806 20.4654 17.7868 21.6551L18.4741 23.6672C18.7393 23.5576 19.0363 23.5535 19.3043 23.6556C19.5724 23.7577 19.7914 23.9584 19.9164 24.2167C20.0414 24.4749 20.0631 24.7711 19.9769 25.0448C19.8907 25.3184 19.7033 25.5488 19.4529 25.6888L19.3955 25.7209C20.2167 26.3531 20.75 27.3364 20.75 28.4574V31.986C20.75 33.6071 19.4107 34.875 17.8171 34.875C16.5082 34.875 15.3714 34.02 15.0063 32.8162H7.36812C7.00362 34.02 5.86681 34.875 4.55788 34.875C2.96431 34.875 1.625 33.6071 1.625 31.986V28.4569C1.625 27.3476 2.14812 26.3734 2.95362 25.74L2.86081 25.6882C2.60663 25.5455 2.4178 25.3096 2.3341 25.0304C2.2504 24.7511 2.27837 24.4503 2.41212 24.1912C2.54587 23.9322 2.77495 23.7352 3.05109 23.6418C3.32723 23.5483 3.62886 23.5657 3.89244 23.6903L4.58825 21.6551ZM5.82969 24.9801L6.71731 22.3824C6.80281 22.131 7.05594 21.9375 7.36812 21.9375H15.0069C15.3185 21.9375 15.5716 22.131 15.6577 22.3824L16.5453 24.9801H5.82969ZM6.06481 27.9203C5.76644 27.9203 5.4803 28.0388 5.26932 28.2498C5.05834 28.4607 4.93981 28.7469 4.93981 29.0453C4.93981 29.3436 5.05834 29.6298 5.26932 29.8407C5.4803 30.0517 5.76644 30.1703 6.06481 30.1703H7.27025C7.56862 30.1703 7.85477 30.0517 8.06574 29.8407C8.27672 29.6298 8.39525 29.3436 8.39525 29.0453C8.39525 28.7469 8.27672 28.4607 8.06574 28.2498C7.85477 28.0388 7.56862 27.9203 7.27025 27.9203H6.06481ZM14.8032 27.9203C14.5049 27.9203 14.2187 28.0388 14.0078 28.2498C13.7968 28.4607 13.6782 28.7469 13.6782 29.0453C13.6782 29.3436 13.7968 29.6298 14.0078 29.8407C14.2187 30.0517 14.5049 30.1703 14.8032 30.1703H16.0087C16.3071 30.1703 16.5932 30.0517 16.8042 29.8407C17.0152 29.6298 17.1337 29.3436 17.1337 29.0453C17.1337 28.7469 17.0152 28.4607 16.8042 28.2498C16.5932 28.0388 16.3071 27.9203 16.0087 27.9203H14.8032Z" fill="#1A1A1A"/>
                        <path d="M9.81097 9.26831C9.60509 9.48414 9.49335 9.77289 9.50031 10.0711C9.50727 10.3693 9.63237 10.6525 9.8481 10.8585L11.1115 12.0639C11.2185 12.166 11.3446 12.246 11.4825 12.2993C11.6205 12.3526 11.7676 12.3782 11.9155 12.3746C12.0633 12.371 12.209 12.3383 12.3442 12.2783C12.4794 12.2183 12.6014 12.1323 12.7033 12.0251L15.3775 9.21262C15.4826 9.10626 15.5655 8.97999 15.6213 8.8412C15.6771 8.70241 15.7047 8.55389 15.7024 8.40433C15.7001 8.25477 15.668 8.10717 15.6079 7.97016C15.5479 7.83315 15.4612 7.70949 15.3528 7.60641C15.2444 7.50333 15.1165 7.4229 14.9767 7.36983C14.8368 7.31676 14.6878 7.2921 14.5383 7.29731C14.3888 7.30252 14.2419 7.33749 14.1061 7.40018C13.9702 7.46286 13.8483 7.55199 13.7473 7.66237L11.8495 9.65812L11.4017 9.23062C11.2948 9.12859 11.1689 9.04862 11.0311 8.99526C10.8933 8.94191 10.7464 8.91622 10.5987 8.91967C10.4509 8.92312 10.3053 8.95563 10.1702 9.01536C10.035 9.07508 9.91298 9.1614 9.81097 9.26831ZM9.84866 17.046C9.64007 16.8385 9.52109 16.5575 9.51724 16.2632C9.51338 15.969 9.62494 15.685 9.82802 15.4721C10.0311 15.2591 10.3095 15.1342 10.6036 15.1241C10.8977 15.1141 11.184 15.2196 11.4012 15.4181L11.8495 15.8456L13.7473 13.8499C13.8483 13.7395 13.9702 13.6504 14.1061 13.5877C14.2419 13.525 14.3888 13.49 14.5383 13.4848C14.6878 13.4796 14.8368 13.5043 14.9767 13.5573C15.1165 13.6104 15.2444 13.6908 15.3528 13.7939C15.4612 13.897 15.5479 14.0207 15.6079 14.1577C15.668 14.2947 15.7001 14.4423 15.7024 14.5918C15.7047 14.7414 15.6771 14.8899 15.6213 15.0287C15.5655 15.1675 15.4826 15.2938 15.3775 15.4001L12.7033 18.2126C12.6014 18.3198 12.4794 18.4058 12.3442 18.4658C12.209 18.5258 12.0633 18.5585 11.9155 18.5621C11.7676 18.5657 11.6205 18.5401 11.4825 18.4868C11.3446 18.4335 11.2185 18.3535 11.1115 18.2514L9.84866 17.046Z" fill="#1A1A1A"/>
                    </svg>
                </div>
                <p class="middle-text">সকল ট্রিপ</p>
            </div>
            <!--<div
                class="w-1/2 py-4 flex flex-col justify-center items-center border border-gray-300 rounded-md cursor-pointer"
                @click="$router.push('/return-trip-create')"
            >
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                        <path d="M5.625 9C5.625 5.89331 8.14331 3.375 11.25 3.375H27C30.1067 3.375 32.625 5.89331 32.625 9V24.75C32.625 27.8567 30.1067 30.375 27 30.375H23.0625C22.7641 30.375 22.478 30.4935 22.267 30.7045C22.056 30.9155 21.9375 31.2016 21.9375 31.5C21.9375 31.7984 22.056 32.0845 22.267 32.2955C22.478 32.5065 22.7641 32.625 23.0625 32.625H27C31.3493 32.625 34.875 29.0993 34.875 24.75V9C34.875 4.65075 31.3493 1.125 27 1.125H11.25C6.90075 1.125 3.375 4.65075 3.375 9V17.4375C3.375 17.7359 3.49353 18.022 3.7045 18.233C3.91548 18.444 4.20163 18.5625 4.5 18.5625C4.79837 18.5625 5.08452 18.444 5.29549 18.233C5.50647 18.022 5.625 17.7359 5.625 17.4375V9Z" fill="#1A1A1A"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.08825 21.6551C4.49437 20.4654 5.62331 19.6875 6.86812 19.6875H14.5069C15.7517 19.6875 16.8806 20.4654 17.2868 21.6551L17.9741 23.6672C18.2393 23.5576 18.5363 23.5535 18.8043 23.6556C19.0724 23.7577 19.2914 23.9584 19.4164 24.2167C19.5414 24.4749 19.5631 24.7711 19.4769 25.0448C19.3907 25.3184 19.2033 25.5488 18.9529 25.6888L18.8955 25.7209C19.7167 26.3531 20.25 27.3364 20.25 28.4574V31.986C20.25 33.6071 18.9107 34.875 17.3171 34.875C16.0082 34.875 14.8714 34.02 14.5063 32.8162H6.86812C6.50362 34.02 5.36681 34.875 4.05788 34.875C2.46431 34.875 1.125 33.6071 1.125 31.986V28.4569C1.125 27.3476 1.64812 26.3734 2.45362 25.74L2.36081 25.6882C2.10663 25.5455 1.9178 25.3096 1.8341 25.0304C1.7504 24.7511 1.77837 24.4503 1.91212 24.1912C2.04587 23.9322 2.27495 23.7352 2.55109 23.6418C2.82723 23.5483 3.12886 23.5657 3.39244 23.6903L4.08825 21.6551ZM5.32969 24.9801L6.21731 22.3824C6.30281 22.131 6.55594 21.9375 6.86812 21.9375H14.5069C14.8185 21.9375 15.0716 22.131 15.1577 22.3824L16.0453 24.9801H5.32969ZM5.56481 27.9203C5.26644 27.9203 4.9803 28.0388 4.76932 28.2498C4.55834 28.4607 4.43981 28.7469 4.43981 29.0453C4.43981 29.3436 4.55834 29.6298 4.76932 29.8407C4.9803 30.0517 5.26644 30.1703 5.56481 30.1703H6.77025C7.06862 30.1703 7.35477 30.0517 7.56574 29.8407C7.77672 29.6298 7.89525 29.3436 7.89525 29.0453C7.89525 28.7469 7.77672 28.4607 7.56574 28.2498C7.35477 28.0388 7.06862 27.9203 6.77025 27.9203H5.56481ZM14.3032 27.9203C14.0049 27.9203 13.7187 28.0388 13.5078 28.2498C13.2968 28.4607 13.1782 28.7469 13.1782 29.0453C13.1782 29.3436 13.2968 29.6298 13.5078 29.8407C13.7187 30.0517 14.0049 30.1703 14.3032 30.1703H15.5087C15.8071 30.1703 16.0932 30.0517 16.3042 29.8407C16.5152 29.6298 16.6337 29.3436 16.6337 29.0453C16.6337 28.7469 16.5152 28.4607 16.3042 28.2498C16.0932 28.0388 15.8071 27.9203 15.5087 27.9203H14.3032Z" fill="#1A1A1A"/>
                        <path d="M24.75 22C24.75 22.4142 25.0858 22.75 25.5 22.75C25.9142 22.75 26.25 22.4142 26.25 22H24.75ZM12.4697 11.4697C12.1768 11.7626 12.1768 12.2374 12.4697 12.5303L17.2426 17.3033C17.5355 17.5962 18.0104 17.5962 18.3033 17.3033C18.5962 17.0104 18.5962 16.5355 18.3033 16.2426L14.0607 12L18.3033 7.75736C18.5962 7.46447 18.5962 6.98959 18.3033 6.6967C18.0104 6.40381 17.5355 6.40381 17.2426 6.6967L12.4697 11.4697ZM26.25 22V15H24.75V22H26.25ZM22.5 11.25L13 11.25V12.75L22.5 12.75V11.25ZM26.25 15C26.25 12.9289 24.5711 11.25 22.5 11.25V12.75C23.7426 12.75 24.75 13.7574 24.75 15H26.25Z" fill="#1A1A1A"/>
                    </svg>
                </div>
                <p class="middle-text">রিটার্ন ট্রিপ</p>
            </div>-->
        </div>

        <div class="flex justify-around gap-2 p-2 w-96 mx-auto">
            <div
                class="w-1/2 py-4 flex flex-col justify-center items-center border border-gray-300 rounded-md cursor-pointer"
                @click="$router.push('/provider-booking-feed')"
            >
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.25376 17.0729C8.69971 15.8196 9.93932 15 11.3062 15H19.6938C21.0607 15 22.3003 15.8196 22.7462 17.0729L23.501 19.1926C23.7921 19.0771 24.1182 19.0728 24.4126 19.1804C24.707 19.288 24.9474 19.4994 25.0847 19.7715C25.2219 20.0435 25.2457 20.3556 25.1511 20.6439C25.0565 20.9321 24.8507 21.1749 24.5757 21.3224L24.5127 21.3561C25.4145 22.0222 26 23.0581 26 24.2391V27.9564C26 29.6643 24.5294 31 22.7796 31C21.3423 31 20.0941 30.0993 19.6932 28.8311H11.3062C10.9059 30.0993 9.65768 31 8.22041 31C6.47062 31 5 29.6643 5 27.9564V24.2385C5 23.0699 5.57441 22.0436 6.45888 21.3763L6.35697 21.3218C6.07787 21.1714 5.87053 20.9229 5.77862 20.6287C5.68672 20.3345 5.71743 20.0176 5.86429 19.7447C6.01115 19.4718 6.26269 19.2643 6.5659 19.1658C6.86911 19.0673 7.20032 19.0857 7.48974 19.2169L8.25376 17.0729ZM9.61691 20.5757L10.5916 17.8391C10.6854 17.5742 10.9634 17.3704 11.3062 17.3704H19.6938C20.036 17.3704 20.3139 17.5742 20.4084 17.8391L21.3831 20.5757H9.61691ZM9.87509 23.6732C9.54747 23.6732 9.23327 23.7981 9.0016 24.0203C8.76994 24.2426 8.63979 24.544 8.63979 24.8584C8.63979 25.1727 8.76994 25.4742 9.0016 25.6964C9.23327 25.9187 9.54747 26.0436 9.87509 26.0436H11.1987C11.5263 26.0436 11.8405 25.9187 12.0722 25.6964C12.3039 25.4742 12.434 25.1727 12.434 24.8584C12.434 24.544 12.3039 24.2426 12.0722 24.0203C11.8405 23.7981 11.5263 23.6732 11.1987 23.6732H9.87509ZM19.4702 23.6732C19.1426 23.6732 18.8284 23.7981 18.5968 24.0203C18.3651 24.2426 18.2349 24.544 18.2349 24.8584C18.2349 25.1727 18.3651 25.4742 18.5968 25.6964C18.8284 25.9187 19.1426 26.0436 19.4702 26.0436H20.7939C21.1215 26.0436 21.4357 25.9187 21.6673 25.6964C21.899 25.4742 22.0291 25.1727 22.0291 24.8584C22.0291 24.544 21.899 24.2426 21.6673 24.0203C21.4357 23.7981 21.1215 23.6732 20.7939 23.6732H19.4702Z" fill="#1A1A1A"/>
                        <path d="M26.625 9.75C26.625 10.7856 28.3038 11.625 30.375 11.625C32.4462 11.625 34.125 10.7856 34.125 9.75C34.125 8.71438 32.4462 7.875 30.375 7.875C28.3038 7.875 26.625 8.71438 26.625 9.75Z" stroke="#1A1A1A" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M26.625 9.75V12.25C26.625 13.285 28.3038 14.125 30.375 14.125C32.4462 14.125 34.125 13.285 34.125 12.25V9.75" stroke="#1A1A1A" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M22.875 4.75C22.875 5.42 23.59 6.03875 24.75 6.37375C25.91 6.70875 27.34 6.70875 28.5 6.37375C29.66 6.03875 30.375 5.42 30.375 4.75C30.375 4.08 29.66 3.46125 28.5 3.12625C27.34 2.79125 25.91 2.79125 24.75 3.12625C23.59 3.46125 22.875 4.08 22.875 4.75Z" stroke="#1A1A1A" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M22.875 4.75V11C22.875 11.555 23.3575 11.9062 24.125 12.25" stroke="#1A1A1A" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M22.875 7.875C22.875 8.43 23.3575 8.78125 24.125 9.125" stroke="#1A1A1A" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <p class="middle-text">আপনার বিড</p>
            </div>
            <div
                class="w-1/2 py-4 flex flex-col justify-center items-center border border-gray-300 rounded-md cursor-pointer"
                @click="$router.push('/booking-confirmed')"
            >
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.25376 17.0729C8.69971 15.8196 9.93932 15 11.3062 15H19.6938C21.0607 15 22.3003 15.8196 22.7462 17.0729L23.501 19.1926C23.7921 19.0771 24.1182 19.0728 24.4126 19.1804C24.707 19.288 24.9474 19.4994 25.0847 19.7715C25.2219 20.0435 25.2457 20.3556 25.1511 20.6439C25.0565 20.9321 24.8507 21.1749 24.5757 21.3224L24.5127 21.3561C25.4145 22.0222 26 23.0581 26 24.2391V27.9564C26 29.6643 24.5294 31 22.7796 31C21.3423 31 20.0941 30.0993 19.6932 28.8311H11.3062C10.9059 30.0993 9.65768 31 8.22041 31C6.47062 31 5 29.6643 5 27.9564V24.2385C5 23.0699 5.57441 22.0436 6.45888 21.3763L6.35697 21.3218C6.07787 21.1714 5.87053 20.9229 5.77862 20.6287C5.68672 20.3345 5.71743 20.0176 5.86429 19.7447C6.01115 19.4718 6.26269 19.2643 6.5659 19.1658C6.86911 19.0673 7.20032 19.0857 7.48974 19.2169L8.25376 17.0729ZM9.61691 20.5757L10.5916 17.8391C10.6854 17.5742 10.9634 17.3704 11.3062 17.3704H19.6938C20.036 17.3704 20.3139 17.5742 20.4084 17.8391L21.3831 20.5757H9.61691ZM9.87509 23.6732C9.54747 23.6732 9.23327 23.7981 9.0016 24.0203C8.76994 24.2426 8.63979 24.544 8.63979 24.8584C8.63979 25.1727 8.76994 25.4742 9.0016 25.6964C9.23327 25.9187 9.54747 26.0436 9.87509 26.0436H11.1987C11.5263 26.0436 11.8405 25.9187 12.0722 25.6964C12.3039 25.4742 12.434 25.1727 12.434 24.8584C12.434 24.544 12.3039 24.2426 12.0722 24.0203C11.8405 23.7981 11.5263 23.6732 11.1987 23.6732H9.87509ZM19.4702 23.6732C19.1426 23.6732 18.8284 23.7981 18.5968 24.0203C18.3651 24.2426 18.2349 24.544 18.2349 24.8584C18.2349 25.1727 18.3651 25.4742 18.5968 25.6964C18.8284 25.9187 19.1426 26.0436 19.4702 26.0436H20.7939C21.1215 26.0436 21.4357 25.9187 21.6673 25.6964C21.899 25.4742 22.0291 25.1727 22.0291 24.8584C22.0291 24.544 21.899 24.2426 21.6673 24.0203C21.4357 23.7981 21.1215 23.6732 20.7939 23.6732H19.4702Z" fill="#1A1A1A"/>
                        <path d="M19.0714 8C19.0714 6.422 20.2706 5.14286 21.75 5.14286H29.25C30.7294 5.14286 31.9286 6.422 31.9286 8V16C31.9286 17.578 30.7294 18.8571 29.25 18.8571H27.375C27.2329 18.8571 27.0967 18.9173 26.9962 19.0245C26.8957 19.1317 26.8393 19.277 26.8393 19.4286C26.8393 19.5801 26.8957 19.7255 26.9962 19.8326C27.0967 19.9398 27.2329 20 27.375 20H29.25C31.3211 20 33 18.2091 33 16V8C33 5.79086 31.3211 4 29.25 4H21.75C19.6789 4 18 5.79086 18 8V12.2857C18 12.4373 18.0564 12.5826 18.1569 12.6898C18.2574 12.7969 18.3936 12.8571 18.5357 12.8571C18.6778 12.8571 18.8141 12.7969 18.9145 12.6898C19.015 12.5826 19.0714 12.4373 19.0714 12.2857V8Z" fill="#1A1A1A"/>
                        <path d="M24 9.71429L25.5 11L28 8" stroke="#1A1A1A" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M24 13.7143L25.5 15L28 12" stroke="#1A1A1A" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <p class="middle-text">বুকিং</p>
            </div>
        </div>

        <!--<Feed  />-->
    </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import TripSearchSection from "@/components/molecule/home/TripSearch";
import FeedSection from "@/components/molecule/feed/PostTripSection";
import userMixin from "@/mixins/userMixin";
import Feed from "@/views/Feed.vue";
import {getProfile} from "@/services/module/profile";
import NidDobUpdate from "@/components/molecule/profile/NidDobUpdate.vue";

import { Swiper, SwiperSlide } from 'swiper/vue';
import {Autoplay, Pagination} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
export default {
	name: "Home",
	mixins: [userMixin],
	components: {
      Feed,
      TripSearchSection,
      FeedSection,
      NidDobUpdate,
      Swiper,
      SwiperSlide,
	},
    setup() {
        return {
            modules: [Pagination, Autoplay],
        };
    },

	data(){
		return {
        swiper: null,
        images: [
            {
                src: "images/slider_1.jpeg",
            },
            {
                src: "images/slider_2.png",
            }
        ]
		}
	},

	computed: {
		...mapGetters({
			user: "user/getUser",
		}),

	},

	methods: {
        ...mapMutations({
            setDialogComponent: "setDialogComponent",
        }),
        // get notification permission from user
        async getNotificationPermission() {
            if (!("Notification" in window)) {
                console.log("This browser does not support desktop notification");
            } else {
                if (Notification.permission === "granted") {
                    console.log("Permission to receive notifications has been granted");
                } else {
                    Notification.requestPermission().then(function (permission) {
                        if (permission === "granted") {
                            console.log("Permission to receive notifications has been granted");
                        }
                    });
                }
                console.log("This browser supports desktop notification")
            }
        },


	},

	created() {
        if (!this.user) {
            this.$router.push({ name: "Signup" });
        }
        this.getNotificationPermission();
        if (this.user) {
            let today = new Date();
            let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
            let localDate = localStorage.getItem('date');
            if (localDate !== date) {
                localStorage.setItem('date', date);
                //this.setDialogComponent('OfferModal')
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.rtb-landing-page {
    height: calc(100vh - 100px);
    font-family: 'Noto Sans Bengali',serif;
    background: #FFF;
}

.middle-text {
    font-size: 10px;
    color: #1A1A1A;
    font-family: 'Noto Sans Bengali', sans-serif;
    font-weight: 700;
    margin-top: 4px;
}
</style>
