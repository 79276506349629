<template>
	<div>
		<slot />
		<Toaster />
	</div>
</template>

<script>
	import Toaster from "@/components/atom/Toaster";
	export default {
		name: 'WithoutHeaderFooter',
		components: {
			Toaster
		}
	}
</script>