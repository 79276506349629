import Form from '../form';
const form = new Form;

export const getProfile = async (userId) => await form.get(`/users/${userId}`);

export const updateProfileImage = async (file) => await form.post('/users/profile/update', file);

export const updateUser = async (userId, formData) => await form.post(`/users/update/${userId}?type=${formData.type}`, formData);

export const updateProfile = async (formData) => await form.post('/users/profile/update', formData);

export const getDrivers = async (query) => await form.get('/driver/index');