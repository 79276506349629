<template>
	<button
		@click="onClickLink(item.link)"
		class="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pb-1 relative"
	>

        <div class="menu-item-bottom relative w-auto">
			<i :class="`text-2xl ${item.link == routeName ? 'text-red-400' :'text-gray-400'} lieding-none ${item.icon}`"></i>
			<span :class="`py-1 capitalize tab tab-home ${item.link == routeName ? 'text-red-500' :'text-gray-500'} block text-xs leading-none -mt-2`"> {{item.title}}</span>

			<span class="flex h-3 w-3 tailwind-ping z-50 absolute top-0 right-3  flex justify-center items-center" v-if="item.title == 'trips' && userType !== 'provider' && requestedTrips > 0">
			<span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
			<span class="relative inline-flex rounded-full h-2 w-2 bg-red-500"></span>
			</span>
		</div>
     </button>
</template>

<script>
    import TripManager from "@/services/module/trip";
	import { mapGetters, mapMutations } from "vuex";
	export default {
		name: 'BottomNavItem',
		data(){
			return {
				userType: '',
				tripManager: new TripManager(),
				requestedTrips: null
			}
		},
		methods: {
		    ...mapMutations({
			   setTrips: "trip/setTrips"
		    }),
			onClickLink(link) {
				this.$router.push({name: link})
			},
			async fetchActiveTripLists() {
			let requestTripsRes = await this.tripManager.trips({
				descOrderColumnName: "created_at",
				status: "hold+started+active",
				//userId: this.userId
			});
			if (requestTripsRes.success) {
				this.requestedTrips = requestTripsRes.data.length
				this.setTrips(requestTripsRes.data);
			}
		},
		},
		computed: {
		    ...mapGetters({
				tripList: "trip/getTrips",
			}),
			routeName() {
				return this.$route.name
			}
		},
		props: {
			item: {
				type: Object
			}
		},

		mounted(){
		    let userData = localStorage.getItem('trip_auth_user')
			if(userData){
				userData = JSON.parse(userData)
				this.userType = userData?.type
			}

            if (this.item.title == 'trips' && this.userType !== 'provider') {
                this.fetchActiveTripLists()
            }

		}
	}
</script>