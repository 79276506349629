<template>
	<div class="md:hidden">
		<div :class="cardBg" class="rounded p-2 grid items-center mb-4 relative">
			<div class="user-name-contact relative flex justify-between items-center">
				<div class="user-number">
					<div class="text-bluishBlack text-sm tracking-widest">
					    <span class="mdi mdi-account"></span> {{ name }}
				    </div>

                    <div
                        class="text-blue-500 text-sm flex flex-wrap"
                        v-if="phone && phone !== '--'"
                    >
                        <a :href="getTel(phone)" class="flex gap-1 items-center">
                            <span class="mdi mdi-phone sm:hidden block"></span>
                            {{ getModifiedPhoneNumber(phone) }}</a
                        >
                    </div>
                    <div class="text-sm tracking-widest">
                        <span class="mdi mdi-star mr-1 text-yellow-500"></span> {{ rating }} Rating
                    </div>
				
				</div>
				
			</div>
			<div
				class="trip-car-infos bg-blue-50 p-2 rounded my-2"
				v-if="trip && trip.trip?.created_by != 'provider'"
			>
                <div v-if="trip && trip.car" class="flex flex-col">
                    <div class="car-data flex gap-x-1 flex-wrap">
                        <span class="mdi mdi-car"></span>
                        <span class="text-gray-500 text-sm">{{ trip.car.brand.name }}</span>
                        <span class="text-gray-500 text-sm">{{ trip.car.model }}</span>
                        <span class="text-gray-500 text-sm">{{ trip.car.manufacture_year }}</span>
                    </div>
                    <div class="car-data flex gap-x-1 flex-wrap">
                        <span class="mdi mdi-seat-recline-normal"></span>
                        <span class="text-gray-500 text-sm">{{ trip.car.number_of_seat }} seats</span>
                        <span class="mx-1 text-gray-500 text-sm">|</span>
                        <span class="text-gray-500 text-sm">{{ trip.car.is_air_conditioned ? "AC" : "Non AC" }}</span>
                    </div>
                </div>
				<!-- <div class="car-data flex gap-x-1" v-if="trip && trip.no_of_seat">
					<p class="text-gray-500 text-sm capitalize">
						<span class="mdi mdi-car"></span>{{ trip.no_of_seat }} passengers
					</p>
				</div> -->

				<!-- <div
					class="trip-car-number"
					v-if="
						trip &&
						trip.car &&
						trip.car.registration_number &&
						trip?.status == 'confirmed'
					"
				>
					<span class="mdi mdi-file"></span>
					{{ trip.car.registration_number }}
				</div> -->
			</div>
            <div v-if="trip?.fixed_price" class="absolute trip-price right-0 top-0">
				<button class="bg-red-400 text-white px-2 rounded">৳ {{trip?.fixed_price}} </button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "UserInformation",
	props: {
		cardBg: {
			default: "bg-red-50",
		},
		title: {
			type: String,
			default: "User Information",
		},
		name: {
			type: String,
		},
		phone: {
			type: String,
		},
		image: {
			type: String,
		},
		trip: {
			type: Object,
		},
        rating: {
            type: String,
            default: "5",
        },
	},

	methods: {
		getModifiedPhoneNumber(number) {
			return number.slice(3);
		},

		getTel(number) {
			return "tel:" + number;
		},
	},
};
</script>
