<template>
    <div class="relative w-full">
        <div
            class="border-l-2 border-dashed border-red-500"
            :class="trip?.status === 'hold' ? 'mt-10' : 'mt-2'"
        >
            <ul>
                <li class="mb-3">
                    <div class="flex">
                        <i style="margin-left: -10px"
                           class="mdi mdi-map-marker mr-3 text-green-300 bg-white h-5 text-lg"></i>
                        <p class="text-gray-500" v-if="trip">
                            {{ trip?.pickup_full_address }}
                        </p>
                    </div>
                </li>
                <li>
                    <div class="flex">
                        <i
                            style="margin-left: -10px"
                            class="mdi mdi-map-marker mr-3 text-blue-300 bg-white text-lg"
                        ></i>
                        <p
                            class="text-gray-500"
                            v-if="trip"
                        >
                            {{ trip?.destination_full_address }}
                        </p>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "DestinationViewer",
    props: ["trip"],
    data() {
        return {
            user: null,
        };
    },
    methods: {
        getAddress(text) {
        },
    },

    mounted() {
        var userdata = localStorage.getItem("trip_auth_user");
        if (userdata) {
            this.user = JSON.parse(userdata);
        }
    },
};
</script>
