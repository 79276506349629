const state = () => ({
    bookingList: []
})

const getters = {
	getBookingList: state => state.bookingList
}

const mutations = {
	setBookings: (state, payload) => state.bookingList = payload,
	//after updating a trip-status from myBooking
	//this function will update trip object inside booking
	updateTripStatusinBooking: (state, payload) => {
		let booking = state.bookingList.find(i => i.trip_id === payload.id);
		booking.trip.status = payload.status;
	},
	updateBookingStatus: (state, payload) => {
		console.log(state.bookingList, 'mutate')
		let booking = state.bookingList.find(i => i.id === payload.id);
		booking.status = payload.status;
	},

	updateBooking: (state, payload) => {
		console.log(payload, 'mu')
		let booking = state.bookingList.find(i => i.id === payload.id);
		booking.status = payload.status;
		booking.contact_number = payload.contact_number;
		console.log(booking, 'after mut')
	}
}

export default {
	namespaced: true,
	state,
	getters,
	mutations
}