/*
this class is responsable for
	-api request
	-error handel(404, 500)
*/
import axios from 'axios';
import Cookie from 'js-cookie';
import store from '../store';
import {clearDeviceInterests} from "@/services/clearDeviceInterests";
const config = {
	onUploadProgress: function(progressEvent) {
	  var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
	  store.commit('updateProgress', percentCompleted);
	}
}

export default class Network{
	constructor() {
		let inpersonateTokenObj = new URLSearchParams(window.location.search);
		let inpersonateToken = inpersonateTokenObj.get('token');
		let inpersonateTokenLocal = localStorage.getItem('imp_token');

		if(inpersonateToken) {
			axios.defaults.headers.common['Impersonate'] = inpersonateToken;
		}
		if(!inpersonateToken && inpersonateTokenLocal) {
			axios.defaults.headers.common['Impersonate'] = inpersonateTokenLocal;
		}
		if(!inpersonateToken && !inpersonateTokenLocal){
			axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
		}
		this.baseURL = process.env.VUE_APP_BASE_URL;
		axios.defaults.baseURL = `${this.baseURL}/api/v1`;
		axios.defaults.withCredentials = true;
	}

	
	async api(method, url, data = null) {
		await this.csrf();
		return new Promise((resolve, reject) => {
			axios[method.toLowerCase()](url, data, config)
			.then(res => {
				resolve(res.data);
		    })
			.catch(err => {
				if(err.response.status == 401) {
					this.unauthenticated()
				}
		        reject(err);
		      })
		});
	}


	unauthenticated() {
		store.commit('setToaster', 'Your session expired. Please login');
		//store.commit('setDialogComponent', 'LoginComponent');

		localStorage.removeItem("trip_auth_user");
		localStorage.removeItem("token");
		localStorage.removeItem("imp_token");
		clearDeviceInterests();
	}


	csrf() {
		let token =	Cookie.get('XSRF-TOKEN');
		if(!token) {
			return	axios.get('/csrf-cookie')
		}

		return new Promise(resolve => resolve(token))

	}

}